import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Break_Page_Test extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            round : 0
        }
    }

    componentDidMount() {
        this.setState({round:localStorage.getItem("round")})
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            {
                                <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>Your prediction has been registered.</h4>
                            }
                            {
                                <h5 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>Now, you'll proceed to the next patient.</h5>
                            }
                        </Col>
                    </Row>
                    <Row style={{'paddingTop': "1%"}}>
                        <Link to={'/patient_presentation?round='+this.state.round}>
                            <Button variant="primary">Go to next patient</Button>
                        </Link>
                    </Row>
                </Container>
            </>
        )
    }

}

export default Break_Page_Test;