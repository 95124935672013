import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";
import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

class Advice_Familiarization extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            progress: 0,
            loading: false,
            error_occurred: false
        }
    }

    componentDidMount() {

            if(localStorage.getItem("group")!=="A"){
                this.intervalId = setInterval(() => {
                  this.setState(prevState => ({
                    progress: prevState.progress + 5
                  }));
                }, 900);}

        this.refreshData()

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    refreshData(){

        // Initializing Progress Bar
        this.setState({progress:0})

        // Preparing arguments for Backend-Counterfactual-Generation
        let round = localStorage.getItem("round")
        let group = localStorage.getItem("group")
        let omitted_features = localStorage.getItem("omitted_features")
        let sparsity_weight = localStorage.getItem("sparsity_weight")

        // Only Groups B, C and D receive any explanations
        if(group !=="A"){
            this.setState({"loading":true});
            axios.get('/api/counterfactual', {
                params: {
                    'session_id':localStorage.getItem('session_id'),
                    'group': group,
                    'round': round,
                    'sparsity_weight':sparsity_weight,
                    "omitted_features":omitted_features
                }
            }
            )
                .then((response) => {
                    // Group D receives the LIME Outputs
                    if(group === "D"){
                        localStorage.setItem("feature_importance" + "_" + round, String(response.data["feature_importance"]))
                    }
                        localStorage.setItem("counterfactual" + "_" + round, String(response.data["counterfactual"]))
                }).catch((err) => {
                    this.setState({
                        error_occurred: true,
                        loading: false
                    })

                   }).finally(() => {this.setState({loading:false})})
        }

        axios.all([
            axios.get('/api/patient', {params: {'round': round}}),
            axios.get('/api/prediction', {params: {'round': round, 'session_id':localStorage.getItem('session_id')}})
        ]).
        then(axios.spread((patient_response, prediction_response ) => {
              localStorage.setItem("prediction"+"_"+round, prediction_response.data["Prediction"]);
              // TODO: Refactoring Backend -> rename "num" to "groundtruth"
              localStorage.setItem("groundtruth"+"_"+round,patient_response.data["num"]);
        }));

    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                        <Container>
                            {
                                !this.state.error_occurred &&
                                <div>
                                    <Row style={{'paddingBottom': "1%"}}>
                                        <Col>
                                            <hr/>
                                            <h6 style={{paddingBottom: "1%"}}>
                                                <b>
                                                    Algorithm decision:
                                                </b>
                                            </h6>
                                            {
                                                localStorage.getItem("prediction" + "_" + localStorage.getItem("round")) === "0" &&
                                                <b>
                                                    <p>The algorithms advice is that this patient would not have a
                                                        heart disease.</p>
                                                </b>
                                            }
                                            {
                                                localStorage.getItem("prediction" + "_" + localStorage.getItem("round")) === "1" &&
                                                <b>
                                                    <p>The algorithms advice is that this patient would have a
                                                        heart disease.</p>
                                                </b>
                                            }
                                            {
                                                this.state.loading === true && (localStorage.getItem("group") !== "A") &&
                                                <div style={{"paddingTop": "1%"}}>
                                                    <p>Please wait shortly while further details are loaded.</p>
                                                    <div style={{
                                                        width: '400px',
                                                        height: '20px',
                                                        border: '1px solid #ccc'
                                                    }}>
                                                        <ProgressBar animated now={this.state.progress}/>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.loading === false && (localStorage.getItem("group") !== "A") &&
                                                <div>
                                                    <br/>
                                                    <p><b>The algorithm would have given the opposite risk advice</b> if <b>ALL</b> of the
                                                        following changes would have been made:</p>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(localStorage.getItem("counterfactual_" + localStorage.getItem("round")))}}>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row style={{'paddingBottom': "1%"}}>
                                        {
                                            <Link to={'/prediction_familiarization'}>
                                                <Button variant="primary">Continue</Button>
                                            </Link>
                                        }
                                    </Row>
                                </div>
                            }
                            {
                                this.state.error_occurred &&
                                <div>
                                    <p>Unfortunately, an error has occurred. Please click on the button below. Sorry!</p>
                                    <Link to={'/'}>
                                        <Button variant="primary">Restart study</Button>
                                    </Link>
                                </div>
                            }
                </Container>
            </>
        )
    }
}

export default Advice_Familiarization;