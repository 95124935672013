import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Introduction_Global_Learning extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                {
                    localStorage.getItem("language")==="English" &&
                    <Container>
                    <Row>
                        <Col style={{'maxWidth':"500px"}}>
                            <h4 style={{'paddingBottom': "2%", 'paddingTop': "2%"}}>In the next step, you will be asked about your intuition.</h4>
                            <h6>(Approximated duration: 3-4 minutes)</h6>
                            <p>
                                Please answer the questions without consulting external sources and as intuitive
                                as possible. <br/>
                            </p>
                            <p>
                                If the term clogged vessels appears in the following:<br/> It refers to "verstopfte Gefäße",
                                and means that the blood within a patient cannot circulate everywhere as it should be.
                            </p>
                            <Link to={'/global_expectation'}>
                                <Button variant="primary">Go to questions</Button>
                            </Link>
                        </Col>
                    </Row>
                    </Container>
                }
                {
                    localStorage.getItem("language")==="German" &&
                    <Container>
                    <Row>
                        <Col style={{'maxWidth':"500px"}}>
                            <h4 style={{'paddingBottom': "2%", 'paddingTop': "2%"}}>Im nächsten Teil ist ihre Intuition gefragt.</h4>
                            <h6>(Geschätzte Dauer: 3-4 Minuten)</h6>
                            <p>
                                Bitte beantworten Sie die Fragen ohne Zuhilfenahme externer Quellen und so intuitiv wie
                                möglich. Um die Vergleichbarkeit zu gewährleisten, sind die nun stattfindenden Teile auf <br/>
                                Englisch ausgeführt.
                            </p>
                            <Link to={'/global_expectation'}>
                                <Button variant="primary">Weiter</Button>
                            </Link>
                        </Col>
                    </Row>
                    </Container>
                }
            </>
        )
    }

}

export default Introduction_Global_Learning;