import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Form} from 'react-bootstrap';
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Language_Selection extends React.Component {

    componentDidMount() {

        // In case an error occurred or the "back"-button of the browser has been clicked
        localStorage.clear()

    }

    setGerman() {
        localStorage.setItem("language","German")
    }

    setEnglish() {
        localStorage.setItem("language","English")
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    {
                                            <div style={{"maxWidth":"540px"}}>
                                                <Row>
                                                    <Col>
                                                        <Link to={'/introduction'} style={{"paddingRight":"1%","paddingBottom":"1%"}}>
                                                            <Button variant="primary" onClick={this.setEnglish}>English</Button>
                                                        </Link>
                                                    </Col>
                                                    <Col>
                                                        <Link to={'/introduction'}>
                                                            <Button variant="primary" onClick={this.setGerman}>Deutsch</Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                </Container>
            </>
        )
    }
}

export default Language_Selection;