import {BrowserRouter, Routes, Route} from "react-router-dom"
import Introduction from './Pages/Introduction'
import Language_Selection from "./Pages/Language_Selection";
import './App.css';
import Outcome_Familiarization from "./Pages/Outcome_Familiarization";
import Introduction_Global_Learning from "./Pages/Introduction_Global_Learning";
import Patient_Presentation from "./Pages/Patient_Presentation";
import Prediction_Results from "./Pages/Prediction_Results";
import Conclude_Study from "./Pages/Conclude_Study";
import Counterfactual from "./Pages/Counterfactual";
import Study_Finished from "./Pages/Study_Finished";
import Patient_Familiarization from "./Pages/Patient_Familiarization";
import Advice_Familiarization from "./Pages/Advice_Familiarization";
import Aggregated_Explanation from "./Pages/Aggregated_Explanation";
import Counterfactual_Feedback_Wrapper from "./Pages/Counterfactual_Feedback_Wrapper";
import Counterfactual_Feedback_Wrapper_Learning from "./Pages/Counterfactual_Feedback_Wrapper_Learning";
import Global_Explanation from "./Pages/Global_Explanation";
import Global_Expectation from "./Pages/Global_Expectation";
import Introduction_Local_Learning from "./Pages/Introduction_Local_Learning";
import Prediction_Familiarization from "./Pages/Prediction_Familiarization";
import Introduction_Test from "./Pages/Introduction_Test";
import Introduction_Counterfactual_Feedback from "./Pages/Introduction_Counterfactual_Feedback";
import Knowledge_Evaluation from "./Pages/Knowledge_Evaluation";
import Break_Page_Test from "./Pages/Break_Page_Test";
import Confirmation_Learning from "./Pages/Confirmation_Learning";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element = {<Language_Selection/>}></Route>
          <Route path="/confirmation_learning" element = {<Confirmation_Learning/>}></Route>
          <Route path="/introduction" element = {<Introduction/>}></Route>
          <Route path="/break_test" element = {<Break_Page_Test/>}></Route>
          <Route path="/knowledge_evaluation" element = {<Knowledge_Evaluation/>}></Route>
          <Route path="/introduction_global_learning" element = {<Introduction_Global_Learning/>}></Route>
          <Route path="/introduction_local_learning" element = {<Introduction_Local_Learning/>}></Route>
          <Route path="/introduction_counterfactual_feedback" element = {<Introduction_Counterfactual_Feedback/>}></Route>
          <Route path="/introduction_test" element = {<Introduction_Test/>}></Route>
          <Route path="/global_explanation" element = {<Global_Explanation/>}></Route>
          <Route path="/global_expectation" element = {<Global_Expectation/>}></Route>
          <Route path="/counterfactual" element = {<Counterfactual/>}></Route>
          <Route path="/interactive_learning" element = {<Counterfactual_Feedback_Wrapper_Learning/>}></Route>
          <Route path="/counterfactual_feedback" element = {<Counterfactual_Feedback_Wrapper/>}></Route>
          <Route path="/aggregated_explanation" element = {<Aggregated_Explanation/>}></Route>
          <Route path="/patient_familiarization" element = {<Patient_Familiarization/>}></Route>
          <Route path="/advice_familiarization" element = {<Advice_Familiarization/>}></Route>
          <Route path="/prediction_familiarization" element = {<Prediction_Familiarization/>}></Route>
          <Route path="/outcome_familiarization" element = {<Outcome_Familiarization/>}></Route>
          <Route path="/patient_presentation" element={<Patient_Presentation/>}></Route>
          <Route path="/prediction_results" element={<Prediction_Results/>}></Route>
          <Route path="/conclude_study" element={<Conclude_Study/>}></Route>
          <Route path="/study_finished" element={<Study_Finished/>}></Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
