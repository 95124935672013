import React from 'react';
import {Container, Row, Col, Navbar, Button, Alert} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import {Option, QuestionGroup, Test} from "react-multiple-choice";

class Knowledge_Evaluation extends React.Component {

     constructor(props) {
         super(props);
         this.state = {
             loaded: false,
             importancy: "no",
             mq_1: false,
             mq_2: false,
             mq_3: false,
             mq_4: false,
             mq_5: false
         }
     }

    componentDidMount() {

         window.scrollTo({
              top: 0, // scroll so that the element is at the top of the view
              behavior: 'smooth' // smooth scroll
            })

         localStorage.setItem("feature",{
                                            "1": "Maximum heart rate at Exercise Test sufficient",
                                            "2": "Number of vessels clogged",
                                            "3": "Heart scan result",
                                            "4": "Feeling chest pain at exercise test"
                                        }[localStorage.getItem("round")])

        this.setState({loaded:true})

    }

    setImportancy = () => {
        if(this.state.importancy === "no"){
            this.setState({"importancy":"yes"})
            localStorage.setItem("importancy_"+localStorage.getItem("round"),"yes")
        } else {
            this.setState({"importancy":"no"})
            localStorage.setItem("importancy_"+localStorage.getItem("round"),"no")
        }
    }

    setAnswer = (answer) => {
        localStorage.setItem("expectation_"+localStorage.getItem("round"),answer)
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                {
                    localStorage.getItem("language")==="German" &&
                    <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <h4 style={{'paddingBottom': "1%", 'paddingTop': '2%'}}>
                                    Bitte beantworten Sie die unten angeführten Fragen.
                                </h4>
                                <p>

                                </p>
                                <p>
                                    Dies dient nur zur Beurteilung Ihrer Kenntnisse im medizinischen Bereich. Bitte <br/>
                                    antworten Sie intuitiv und ohne Rückgriff auf externe Quellen.
                                </p>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Frage 1:</h3>
                                        <p>Was ist der normale Bereich für die menschliche Körpertemperatur?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_1",JSON.stringify(selectedOptions["0"])); this.setState({mq_1:true})}}>
                                          <QuestionGroup questionNumber={0}>
                                            <Option value="35-37">35-37°C</Option>
                                            <Option value="30-32">30-32°C</Option>
                                            <Option value="40-42">40-42°C</Option>
                                            <Option value="25-27">25-27°C</Option>
                                            <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Frage 2:</h3>
                                        <p>Welches Organ ist für die Insulinproduktion im menschlichen Körper zuständig?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_2",JSON.stringify(selectedOptions["1"])); this.setState({mq_2:true})}}>
                                          <QuestionGroup questionNumber={1}>
                                            <Option value="Leber">Leber</Option>
                                            <Option value="Pankreas">Pankreas</Option>
                                            <Option value="Niere">Niere</Option>
                                            <Option value="Magen">Magen</Option>
                                              <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Frage 3:</h3>
                                        <p>Welche der folgenden ist kein Typ von Blutzelle?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_3",JSON.stringify(selectedOptions["2"])); this.setState({mq_3:true})}}>
                                          <QuestionGroup questionNumber={2}>
                                            <Option value="Rote Blutzelle">Rote Blutzelle</Option>
                                            <Option value="Weiße Blutzelle">Weiße Blutzelle</Option>
                                            <Option value="Thrombozyten">Thrombozyten</Option>
                                            <Option value="Plasmazelle">Plasmazelle</Option>
                                              <Option value="Stammzelle">Stammzelle</Option>
                                              <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Frage 4:</h3>
                                        <p>Welcher der folgenden ist der größte menschliche Knochen?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_4",JSON.stringify(selectedOptions["3"])); this.setState({mq_4:true})}}>
                                          <QuestionGroup questionNumber={3}>
                                            <Option value="Femur">Femur</Option>
                                            <Option value="Humerus">Humerus</Option>
                                            <Option value="Tibia">Tibia</Option>
                                            <Option value="Radius">Radius</Option>
                                              <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div style={{"paddingBottom":"1%"}}>
                                        <h3>Frage 5:</h3>
                                        <p>Welches Hormon reguliert den Schlaf-Wach-Zyklus des Körpers?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_5",JSON.stringify(selectedOptions["4"])); this.setState({mq_5:true})}}>
                                          <QuestionGroup questionNumber={4}>
                                            <Option value="Melatonin">Melatonin</Option>
                                            <Option value="Insulin">Insulin</Option>
                                            <Option value="Thyroxine">Thyroxin</Option>
                                            <Option value="Adrenaline">Adrenalin</Option>
                                              <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{"paddingBottom":"1%"}}>
                                        <h3>Frage 6:</h3>
                                        <p>Welcher Cholesterol-Typ wird als 'gut' für die Gesundheit des Herzens angesehen?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_6",JSON.stringify(selectedOptions["5"])); this.setState({mq_6:true})}}>
                                          <QuestionGroup questionNumber={5}>
                                            <Option value="HDL">HDL</Option>
                                            <Option value="LDL">LDL</Option>
                                            <Option value="VLDL">VLDL</Option>
                                            <Option value="Triglyzeride">Triglyzeride</Option>
                                              <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{"paddingBottom":"1%"}}>
                                        <h3>Frage 7:</h3>
                                        <p>Welche Krankheit wird oft als "stiller Killer" bezeichnet, weil sie oft keine Symptome zeigt, bis es zu spät ist?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_7",JSON.stringify(selectedOptions["6"])); this.setState({mq_7:true})}}>
                                          <QuestionGroup questionNumber={6}>
                                            <Option value="Hypertension (high blood pressure)">Hypertension (Bluthochdruck)</Option>
                                            <Option value="Atherosclerosis (hardening and narrowing of the arteries)">Atherosclerose (Verhärtung und Verengung der Arterien)</Option>
                                            <Option value="Type 2 diabetes">Typ 2 diabetes</Option>
                                            <Option value="Hypercholesterolemia (high blood cholesterol levels)">Hypercholesterolemie (hohe Cholesterol-Level im Blut)</Option>
                                              <Option value="Ich bin mir nicht sicher">Ich bin mir nicht sicher</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                            </Col>
                            <br/>
                            <hr/>
                            {
                                // Groups A, B und C
                                localStorage.getItem("medical_question_1")!== null && localStorage.getItem("medical_question_2")!== null && localStorage.getItem("medical_question_3")!== null && localStorage.getItem("medical_question_4")!== null && localStorage.getItem("medical_question_5")!== null && localStorage.getItem("medical_question_6")!== null && localStorage.getItem("medical_question_7")!== null && localStorage.getItem("group")!=="D" &&
                                <Link to={'/introduction_local_learning'}>
                                    <Button variant="primary">Weiter</Button>
                                </Link>
                            }
                            {
                                // Groups A.1, B.1 and C.1 (Global Explanations)
                                localStorage.getItem("medical_question_1")!== null && localStorage.getItem("medical_question_2")!== null && localStorage.getItem("medical_question_3")!== null && localStorage.getItem("medical_question_4")!== null && localStorage.getItem("medical_question_5")!== null && localStorage.getItem("medical_question_6")!== null && localStorage.getItem("medical_question_7")!== null && localStorage.getItem("group")==="D" &&
                                <Link to={'/introduction_global_learning'}>
                                    <Button variant="primary">Weiter</Button>
                                </Link>
                            }
                        </Row>
                </Container>
                }
                {
                    localStorage.getItem("language")==="English" &&
                    <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <h4 style={{'paddingBottom': "1%", 'paddingTop': '2%'}}>
                                    Please answer the questions shown below.
                                </h4>
                                <p>
                                    This is only for evaluating your knowledge in the medical field, please <br/>
                                    answer intuitively and without consulting external sources.
                                </p>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Question 1:</h3>
                                        <p>What is the normal range for human body temperature?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_1",JSON.stringify(selectedOptions["0"])); this.setState({mq_1:true})}}>
                                          <QuestionGroup questionNumber={0}>
                                            <Option value="35-37">35-37°C</Option>
                                            <Option value="30-32">30-32°C</Option>
                                            <Option value="40-42">40-42°C</Option>
                                            <Option value="25-27">25-27°C</Option>
                                            <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Question 2:</h3>
                                        <p>Which organ is responsible for producing insulin in the human body?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_2",JSON.stringify(selectedOptions["1"])); this.setState({mq_2:true})}}>
                                          <QuestionGroup questionNumber={1}>
                                            <Option value="Liver">Liver</Option>
                                            <Option value="Pancreas">Pancreas</Option>
                                            <Option value="Kidney">Kidney</Option>
                                            <Option value="Stomach">Stomach</Option>
                                              <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Question 3:</h3>
                                        <p>Which of the following is not a type of blood cell?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_3",JSON.stringify(selectedOptions["2"])); this.setState({mq_3:true})}}>
                                          <QuestionGroup questionNumber={2}>
                                            <Option value="Red blood cell">Red blood cell</Option>
                                            <Option value="White blood cell">White blood cell</Option>
                                            <Option value="Platelet">Platelet</Option>
                                            <Option value="Plasma cell">Plasma cell</Option>
                                              <Option value="Stem cell">Stem cell</Option>
                                              <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div>
                                        <h3>Question 4:</h3>
                                        <p>Which is the largest bone in the human body?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_4",JSON.stringify(selectedOptions["3"])); this.setState({mq_4:true})}}>
                                          <QuestionGroup questionNumber={3}>
                                            <Option value="Femur">Femur</Option>
                                            <Option value="Humerus">Humerus</Option>
                                            <Option value="Tibia">Tibia</Option>
                                            <Option value="Radius">Radius</Option>
                                              <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <div style={{"paddingBottom":"1%"}}>
                                        <h3>Question 5:</h3>
                                        <p>Which hormone regulates the body's sleep-wake cycle?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_5",JSON.stringify(selectedOptions["4"])); this.setState({mq_5:true})}}>
                                          <QuestionGroup questionNumber={4}>
                                            <Option value="Melatonin">Melatonin</Option>
                                            <Option value="Insulin">Insulin</Option>
                                            <Option value="Thyroxine">Thyroxine</Option>
                                            <Option value="Adrenaline">Adrenaline</Option>
                                              <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{"paddingBottom":"1%"}}>
                                        <h3>Question 6:</h3>
                                        <p>Which type of cholesterol is considered "good" for heart health?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_6",JSON.stringify(selectedOptions["5"])); this.setState({mq_6:true})}}>
                                          <QuestionGroup questionNumber={5}>
                                            <Option value="HDL">HDL</Option>
                                            <Option value="LDL">LDL</Option>
                                            <Option value="VLDL">VLDL</Option>
                                            <Option value="Triglycerides">Triglycerides</Option>
                                              <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{"paddingBottom":"1%"}}>
                                        <h3>Question 7:</h3>
                                        <p>Which condition is often referred to as the "silent killer" because it often has no symptoms until it's too late?</p>
                                       <Test onOptionSelect={selectedOptions => {localStorage.setItem("medical_question_7",JSON.stringify(selectedOptions["6"])); this.setState({mq_7:true})}}>
                                          <QuestionGroup questionNumber={6}>
                                            <Option value="Hypertension (high blood pressure)">Hypertension (high blood pressure)</Option>
                                            <Option value="Atherosclerosis (hardening and narrowing of the arteries)">Atherosclerosis (hardening and narrowing of the arteries)</Option>
                                            <Option value="Type 2 diabetes">Type 2 diabetes</Option>
                                            <Option value="Hypercholesterolemia (high blood cholesterol levels)">Hypercholesterolemia (high blood cholesterol levels)</Option>
                                              <Option value="I don't know">I don't know</Option>
                                          </QuestionGroup>
                                       </Test>
                                    </div>
                                </Row>
                            </Col>
                            <br/>
                            <hr/>
                            {
                                // Groups A, B and C
                                localStorage.getItem("medical_question_1")!== null && localStorage.getItem("medical_question_2")!== null && localStorage.getItem("medical_question_3")!== null && localStorage.getItem("medical_question_4")!== null && localStorage.getItem("medical_question_5")!== null && localStorage.getItem("medical_question_6")!== null && localStorage.getItem("medical_question_7")!== null && localStorage.getItem("group")!=="D" &&
                                <Link to={'/introduction_local_learning'}>
                                    <Button variant="primary">Proceed</Button>
                                </Link>
                            }
                            {
                                // Group D
                                localStorage.getItem("medical_question_1")!== null && localStorage.getItem("medical_question_2")!== null && localStorage.getItem("medical_question_3")!== null && localStorage.getItem("medical_question_4")!== null && localStorage.getItem("medical_question_5")!== null && localStorage.getItem("medical_question_6")!== null && localStorage.getItem("medical_question_7")!== null && localStorage.getItem("group")==="D" &&
                                <Link to={'/introduction_global_learning'}>
                                    <Button variant="primary">Proceed</Button>
                                </Link>
                            }
                        </Row>
                </Container>
                }
            </>
        )
    }
}

export default Knowledge_Evaluation;