import React from 'react';
import 'react-chat-widget/lib/styles.css';
import Counterfactual_Feedback from "./Counterfactual_Feedback";

export default class Counterfactual_Feedback_Wrapper extends React.Component {

    render() {
        return <Counterfactual_Feedback/>
    }

}