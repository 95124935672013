import React from 'react';
import {Container, Row, Col, Navbar} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";

class Study_Finished extends React.Component {

    constructor(props){
        super(props);
        this.state = {
           participation: "no"
        }
    }

    componentDidMount() {

        axios.post('/api/resultpersistence',
            {
                session_id: localStorage.getItem("session_id"),
                group: localStorage.getItem("group"),
                all_user_feedback: localStorage.getItem("all_user_feedback"),
                corrected_features: localStorage.getItem("corrected_features"),
                omitted_features: localStorage.getItem("omitted_features"),
                sparsity_weight: localStorage.getItem("sparsity_weight"),
                answers_questionnaire: localStorage.getItem("answers_questionnaire"),
                QuestionnaireFreeText: localStorage.getItem("QuestionnaireFreeText"),
                system_prediction_1_global: localStorage.getItem("prediction_1_"+localStorage.getItem("group")),
                system_prediction_2_global: localStorage.getItem("prediction_2_"+localStorage.getItem("group")),
                system_prediction_3_global: localStorage.getItem("prediction_3_"+localStorage.getItem("group")),
                system_prediction_4_global: localStorage.getItem("prediction_4_"+localStorage.getItem("group")),
                system_prediction_5_global: localStorage.getItem("prediction_5_"+localStorage.getItem("group")),
                system_behavior_prediction_1: localStorage.getItem("system_behavior_prediction_1"),
                system_behavior_prediction_2: localStorage.getItem("system_behavior_prediction_2"),
                system_behavior_prediction_3: localStorage.getItem("system_behavior_prediction_3"),
                system_behavior_prediction_4: localStorage.getItem("system_behavior_prediction_4"),
                system_behavior_prediction_5: localStorage.getItem("system_behavior_prediction_5"),
                system_behavior_prediction_6: localStorage.getItem("system_behavior_prediction_6"),
                system_behavior_prediction_7: localStorage.getItem("system_behavior_prediction_7"),
                system_behavior_prediction_8: localStorage.getItem("system_behavior_prediction_8"),
                system_behavior_prediction_9: localStorage.getItem("system_behavior_prediction_9"),
                system_behavior_prediction_10: localStorage.getItem("system_behavior_prediction_10"),
                system_prediction_interactive_learning_1: localStorage.getItem("1_prediction_cf_feedback"),
                system_prediction_interactive_learning_2: localStorage.getItem("2_prediction_cf_feedback"),
                system_prediction_interactive_learning_3: localStorage.getItem("3_prediction_cf_feedback"),
                system_prediction_interactive_learning_4: localStorage.getItem("4_prediction_cf_feedback"),
                system_prediction_interactive_learning_5: localStorage.getItem("5_prediction_cf_feedback"),
                not_convinced: localStorage.getItem("notConvinced"),
                expectation_1: localStorage.getItem("expectation_1"),
                expectation_2: localStorage.getItem("expectation_2"),
                expectation_3: localStorage.getItem("expectation_3"),
                expectation_4: localStorage.getItem("expectation_4"),
                expectation_5: localStorage.getItem("expectation_5"),
                prediction_1: localStorage.getItem("prediction_1"),
                prediction_2: localStorage.getItem("prediction_2"),
                prediction_3: localStorage.getItem("prediction_3"),
                prediction_4: localStorage.getItem("prediction_4"),
                prediction_5: localStorage.getItem("prediction_5"),
                groundtruth_1: localStorage.getItem("groundtruth_1"),
                groundtruth_2: localStorage.getItem("groundtruth_2"),
                groundtruth_3: localStorage.getItem("groundtruth_3"),
                groundtruth_4: localStorage.getItem("groundtruth_4"),
                groundtruth_5: localStorage.getItem("groundtruth_5"),
                counterfactual_1: localStorage.getItem("counterfactual_1"),
                counterfactual_2: localStorage.getItem("counterfactual_2"),
                counterfactual_3: localStorage.getItem("counterfactual_3"),
                counterfactual_4: localStorage.getItem("counterfactual_4"),
                counterfactual_5: localStorage.getItem("counterfactual_5"),
                age: localStorage.getItem("age"),
                programming_knowledge: localStorage.getItem("programming_knowledge"),
                domain_knowledge: localStorage.getItem("domain_knowledge"),
                medical_question_1: localStorage.getItem("medical_question_1"),
                medical_question_2: localStorage.getItem("medical_question_2"),
                medical_question_3: localStorage.getItem("medical_question_3"),
                medical_question_4: localStorage.getItem("medical_question_4"),
                medical_question_5: localStorage.getItem("medical_question_5"),
                medical_question_6: localStorage.getItem("medical_question_6"),
                medical_question_7: localStorage.getItem("medical_question_7"),
                current_counterfactual_state: localStorage.getItem("current_counterfactual_state"),
                most_frequent_changes: localStorage.getItem("most_frequent_changes"),
                language:localStorage.getItem("language"),
                answer_confidence_6:localStorage.getItem("answer_confidence_6"),
                answer_confidence_7:localStorage.getItem("answer_confidence_7"),
                answer_confidence_8:localStorage.getItem("answer_confidence_8"),
                answer_confidence_9:localStorage.getItem("answer_confidence_9"),
                answer_confidence_10:localStorage.getItem("answer_confidence_10"),
                answer_confidence_familiarization1:localStorage.getItem("answer_confidence_6"),
                answer_confidence_familiarization2:localStorage.getItem("answer_confidence_7"),
                answer_confidence_familiarization3:localStorage.getItem("answer_confidence_8"),
                answer_confidence_familiarization4:localStorage.getItem("answer_confidence_9"),
                answer_confidence_familiarization5:localStorage.getItem("answer_confidence_10"),
            }
        )
    }

    activateParticipation = () => {
        if(this.state.participation === "no"){
            this.setState({"participation":"yes"})
        } else {
            this.setState({"participation":"no"})
        }
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                    <Container>
                        <Navbar.Brand>
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            <p style={{"paddingTop":"2%", "maxWidth":"450px"}}>
                                Thanks for your participation. You have now finished the study. If you also want to participate in the giveaway, please
                                click on the button (your mail program will open with a pre-filled mail, just click on 'send'):
                            </p>
                            <p>
                                Before proceeding, you have to consent to the minimum necessary use of your supplied
                                data. Your mail address will only be used for the (random) drawing of the vouchers by staff
                                of the University of Vienna and contact possibility in case you've won. <br/><br/>
                                <form>
                                    <table>
                                        <tr>
                                            <td>
                                                <input type="checkbox" id="consent" onChange={this.activateParticipation}/>
                                            </td>
                                            <td>
                                                <label style={{"paddingLeft":"1%"}} htmlFor="consent"> I hereby consent to the above mentioned.</label>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </p>
                            {
                                this.state.participation === "yes" &&
                                <div>
                                    <form action="mailto:masterarbeit.felix.lindau@gmail.com" method="GET" style={{"paddingBottom":"2%"}}>
                                        <input name="body" value={localStorage.getItem("session_id")} type="hidden"/>
                                        <input type="submit" value="Go!"/>
                                    </form>
                                    <p>
                                        Link not working? <br/> Just send a mail to masterarbeit.felix.lindau@gmail.com
                                        with the Code "{localStorage.getItem("session_id")}".
                                    </p>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
export default Study_Finished;