import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Outcome_Familiarization extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            progress: 0,
            loading: false,
            translation: {
                "1": "risk patient",
                "0": "no risk patient",
                '"no"': "no risk patient",
                '"yes"': "risk patient"
            }
        }
    }

    componentDidMount() {

        this.intervalId = setInterval(() => {
                  this.setState(prevState => ({
                    progress: prevState.progress + 5
                  }));
                }, 900);

        this.refreshData()

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    refreshData(){

        this.setState({progress:0})

        let round = localStorage.getItem("round")
        let group = localStorage.getItem("group")
        let omitted_features = localStorage.getItem("omitted_features")
        let sparsity_weight = localStorage.getItem("sparsity_weight")

        this.setState({"loading":true});
            axios.get('/api/counterfactual', {
                params: {
                    'session_id':localStorage.getItem('session_id'),
                    'group': group,
                    'round': round,
                    'sparsity_weight':sparsity_weight,
                    "omitted_features":omitted_features
                }
            }
            )
                .then((response) => {
                    if(group === "C.0" || group === "C.1"){
                        localStorage.setItem("counterfactual" + "_" + round, String(response.data["counterfactual"]))
                        localStorage.setItem("feature_importance" + "_" + round, String(response.data["feature_importance"]))
                        this.setState({"explanations":"yes"});
                    } else {
                        localStorage.setItem("counterfactual" + "_" + round, String(response.data["counterfactual"]))
                        this.setState({"explanations":"yes"});
                    }
                }).catch((err) => {
                    console.log('Error occurred' + err)}).finally(() => {this.setState({loading:false})})

        axios.all([
            axios.get('/api/patient', {params: {'round': round}}),
            axios.get('/api/prediction', {params: {'round': round,'session_id':localStorage.getItem("session_id")}})]).
        then(axios.spread((patient_response, prediction_response ) => {
              localStorage.setItem("prediction"+"_"+round, prediction_response.data["Prediction"]);
              localStorage.setItem("groundtruth"+"_"+round,patient_response.data["num"]);
              this.setState({"patient":patient_response.data})
        }));

    }

    updateRound = () => {

        localStorage.setItem("round",String(parseInt(localStorage.getItem("round"))+1))

        this.refreshData()

        window.scrollTo({
              top: 0,
              behavior: 'smooth'
        });

    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                        <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <hr/>
                                <h6>
                                    <b>
                                      Real outcome:
                                    </b>
                                </h6>
                                {
                                    localStorage.getItem("groundtruth" + "_" + localStorage.getItem("round")) === "0" &&
                                    "In the end, this patient is not a risk patient for a heart disease. "
                                }
                                {
                                    localStorage.getItem("groundtruth" + "_" + localStorage.getItem("round")) === "1" &&
                                    "In the end, this patient is a risk patient for a heart disease. "
                                }
                                <hr/>
                                <p>
                                    See your decision and the algorithm's advice below:
                                </p>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <tr>
                                            <th>Algorithm advice</th>
                                            <td>{this.state.translation[localStorage.getItem("prediction" + "_" + localStorage.getItem("round"))]}</td>
                                        </tr>
                                        <tr>
                                            <th>Your decision</th>
                                            <td>{this.state.translation[localStorage.getItem("system_behavior_prediction_"+localStorage.getItem("round"))]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <hr/>
                        <Row style={{'paddingBottom': "1%"}}>
                            {
                                (parseInt(localStorage.getItem("round")) < 5) &&
                                <Link to={'/patient_familiarization'}>
                                <Button variant="outline-primary" onClick={this.updateRound}>Show next Example</Button>
                                </Link>
                            }
                            {
                                (parseInt(localStorage.getItem("round")) === 5) &&
                                <Link to={'/introduction_test'}>
                                <Button variant="primary">Continue Study</Button>
                                </Link>
                            }
                        </Row>
                </Container>
            </>
        )
    }
}

export default Outcome_Familiarization;