import React from 'react';
import {Container, Row, Col, Navbar, Button, Table, FormText, FormLabel, Form} from 'react-bootstrap'
import Likert from 'react-likert-scale'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Conclude_Study extends React.Component {

    state = {
        answers: {},
        freeTextInput: "",
        r4: {
            question: "1.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["r4"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        t3: {
            question: "2.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["t3"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        u1: {
            question: "3.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["u1"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        u2: {
            question: "4.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["u2"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        u3: {
            question: "5.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["u3"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        u4: {
            question: "6.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["u4"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        u5: {
            question: "7.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["u5"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        f2: {
            question: "8.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["f2"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
        p4: {
            question: "9.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["p4"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answers_questionnaire",JSON.stringify(this.state.answers))
            }
        },
    };

    handleSubmit = (event) => {
      event.preventDefault();
      this.props.history.push('/Study_Finished');
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <h5 style={{'paddingTop':'5%', 'paddingBottom':'5%'}}>Congratulations, you've finished the study! <br/>Before entering into the chance for the vouchers,
                            please answer a few questions below.
                        </h5>
                        <hr/>
                    </Row>
                    <Row>
                        {/*Reliability*/}
                        <Col>
                            <p>I can rely on the algorithm to function properly.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.r4} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                    </Row>
                    <Row>
                        {/*Perceived Technical Competence*/}
                        <Col>
                            <p>The advice the algorithm produces is as good as that which a highly competent person could produce.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.t3} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                    </Row>
                    <Row>
                        {/*Perceived Understandability*/}
                        <Col>
                           <p>
                            I know what will happen the next time I use the algorithm because I understand how it behaves.
                            </p>
                        </Col>
                        <Col>
                            <Likert {...this.state.u1} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                        <Col>
                           <p>
                            I understand how the algorithm will assist me with decisions I have to make.
                            </p>
                        </Col>
                        <Col>
                            <Likert {...this.state.u2} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                        <Col>
                            <p>Although I may not know exactly how the algorithm works, I know how to use it to make decisions about the problem.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.u3} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                        <Col>
                            <p>It is easy to follow what the algorithm does.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.u4} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                        <Col>
                            <p>I recognize what I should do to get the advice I need from the algorithm the next time I use it.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.u5} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                    </Row>
                    <Row>
                        {/*Faith*/}
                        <Col>
                            <p>When I am uncertain about a decision I believe the algorithm rather than myself.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.f2} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                    </Row>
                    <Row>
                        {/*Personal Attachment*/}
                        <Col>
                            <p>I like using the algorithm for decision making.</p>
                        </Col>
                        <Col>
                            <Likert {...this.state.p4} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                        </Col>
                        <hr/>
                    </Row>
                    <Row>
                        <p>Any additional thoughts? You may leave them here:</p>
                        <textarea onChange={e => localStorage.setItem("QuestionnaireFreeText",e.target.value)}/>
                        <Row style={{'paddingTop': "2%", 'paddingBottom': "10%"}}>
                        {
                            <Link to={'/Study_Finished'}>
                               <Button variant="primary">Finish study</Button>
                           </Link>
                        }
                        </Row>
                    </Row>
                </Container>
            </>
        )
    }

}

export default Conclude_Study;