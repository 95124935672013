import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Confirmation_Learning extends React.Component {

    componentDidMount() {
        localStorage.setItem("round",String((parseInt(localStorage.getItem("round"))+1)))
        localStorage.setItem("nextRound","true")
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            {
                                <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>You have completed this round.</h4>
                            }
                            {
                                <h5 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>Now, you'll proceed to the next patient.</h5>
                            }
                        </Col>
                    </Row>
                    <Row style={{'paddingTop': "1%"}}>
                        <Link to={'/interactive_learning'}>
                            <Button variant="primary">Go to next patient</Button>
                        </Link>
                    </Row>
                </Container>
            </>
        )
    }

}

export default Confirmation_Learning;