import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Introduction_Local_Learning extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        localStorage.setItem("round","1")
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                {
                    localStorage.getItem("language")==="English" &&
                    <Container>
                        {
                            localStorage.getItem("group")!=="C" && localStorage.getItem("group")!=="D" &&
                            <Row>
                                <Col style={{"maxWidth": "550px"}}>
                                    <h4 style={{'paddingTop': "1%", 'paddingBottom': "2%"}}>In the next steps, you are presented 5 different patients
                                        and the algorithm's advice on their heart disease risk.</h4>
                                    <h6>(Approximated duration: 2-3 minutes)</h6>
                                    <div style={{"maxWidth": "500px"}}>
                                        <p>
                                        If the term clogged vessels appears in the following: <br/>It refers to "verstopfte Gefäße",
                                        and means that the blood within a patient cannot circulate everywhere as it should be.
                                        </p>
                                    </div>
                                    <Link to={'/patient_familiarization'}>
                                        <Button variant="primary">Proceed with study</Button>
                                    </Link>
                                </Col>
                            </Row>
                        }
                        {
                            localStorage.getItem("group")==="C" || localStorage.getItem("group")==="D" &&
                            <Row>
                                <Col>
                                    <h4 style={{'paddingBottom': "2%", 'paddingTop': "2%"}}>In the next step, you will be asked to simulate the algorithms behavior in 5 rounds.</h4>
                                    <h6>(Approximated duration: 3-4 minutes)</h6>
                                    <p>
                                        First, you will be presented a new patient and the algorithm's advice.
                                    </p>
                                    <p>
                                        Then, you will be asked to type into a textfield which characteristic of the patient you
                                        think should be changed first, in order for the algorithm to change its advice.
                                        (e.g. "the smoking history should be lower")
                                    </p>
                                    <p>
                                        This process will be repeated until you found a complete set of changes. 'Complete set'
                                        here refers to a set of changes that, if they would all be applied to the patient, lead
                                        to an algorithm advice in the opposite category. (e.g. prior was "risk", then "no risk").
                                        You will receive recommendations for suitable changes.
                                    </p>
                                    <Link to={'/interactive_learning?round=1'}>
                                        <Button variant="primary">Proceed with study</Button>
                                    </Link>
                                </Col>
                            </Row>
                        }
                    </Container>
                }
                {
                    localStorage.getItem("language")==="German" &&
                    <Container>
                        {
                            localStorage.getItem("group")==="C" &&
                            <Row>
                                <Col>
                                    <h4 style={{'paddingBottom': "2%", 'paddingTop': "2%"}}>Im nächsten Schritt werden Sie gebeten, das Verhalten des Algorithmus zu simulieren.</h4>
                                    <h6>(Geschätzte Dauer: 3-4 Minuten)</h6>
                                    <p>
                                        Zunächst wird Ihnen ein neuer Patient und der Ratschlag des Algorithmus präsentiert.
                                    </p>
                                    <p>
                                        Anschließend werden Sie aufgefordert, in ein Textfeld einzugeben, welche Eigenschaft des
                                        Patienten Ihrer Meinung nach als Erstes geändert werden sollte, damit der Algorithmus seinen Ratschlag ändert.
                                        (z.B. "Die Rauchgeschichte sollte niedriger sein")
                                    </p>
                                    <p>
                                        Dieser Prozess wird wiederholt, bis Sie einen vollständigen Satz von Änderungen gefunden haben.
                                        'Vollständiger Satz' bezieht sich hier auf eine Reihe von Änderungen, die, wenn sie alle auf den Patienten angewendet werden,
                                        zu einem Algorithmus-Ratschlag in der gegenteiligen Kategorie führen würden (z.B. vorher "Risiko", dann "kein Risiko").
                                        Sie erhalten Empfehlungen für geeignete Änderungen.
                                    </p>
                                    <Link to={'/interactive_learning?round=1'}>
                                        <Button variant="primary">Mit der Studie fortfahren</Button>
                                    </Link>
                                </Col>
                            </Row>
                        }
                        {
                            localStorage.getItem("group")!=="C" &&
                            <Row>
                                <Col style={{"maxWidth": "550px"}}>
                                    <h4 style={{'paddingTop': "1%", 'paddingBottom': "2%"}}>Auf den nächsten Seiten werden Ihnen
                                        5 unterschiedliche Patienten und der jeweilige Advice des Algorithmus hinsichtlich
                                        des Herzerkrankungs-Risiko des Patienten gezeigt.</h4>
                                    <h5>Aus Gründen der Vergleichbarkeit findet die restliche Studie auf Englisch statt.</h5>
                                    <h6>(Geschätzte Dauer: 2-3 Minuten)</h6>
                                    <Link to={'/patient_familiarization'}>
                                        <Button variant="primary">Weiter</Button>
                                    </Link>
                                </Col>
                            </Row>
                        }
                    </Container>
                }

            </>
        )
    }
}

export default Introduction_Local_Learning;