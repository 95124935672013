import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import {Tooltip} from "react-tooltip";
import {Option, QuestionGroup, Test} from "react-multiple-choice";
import Likert from "react-likert-scale";

class Patient_Presentation extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            system_behavior_prediction:"",
            patient: {},
            answers: {},
            r1: {
            question: "I am confident in this decision.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["r1"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answer_confidence_"+localStorage.getItem("round"),JSON.stringify(this.state.answers))
            }
            },
            translation: {
                "Sex":{
                    0:"female",
                    1:"male"
                },
                "thalach_flg": {
                    0:"no",
                    1:"yes"
                },
                "famhist":{
                    0:"no",
                    1:"yes"
                },
                "exang":{
                    0:"no",
                    1:"yes"
                },
                "thal":{
                    0:"no defect",
                    1:"fixed defect detected",
                    2:"reversible defect detected"
                },
                "Chest pain":{
                    0:"no",
                    1:"yes",
                },
                "Blood sugar level":{
                    0:"no",
                    1:"yes"
                },
                "Blood pressure in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Heart rate in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Cholesterol level":{
                    0:"no",
                    1:"yes"
                }
            }
        }
    }

    componentDidMount() {

        let round = (new URLSearchParams(window.location.search)).get("round")
        localStorage.setItem("round",round)
        this.setState({"round":round})

        axios.get('/api/patient', {params: {'round': round}})
          .then((response) => {
              localStorage.setItem("round",round)
              this.setState({"patient": response.data})
          })
          .catch((err) => {
              console.log('Error occurred' + err);
          });

        window.scrollTo({
              top: 0,
              behavior: 'smooth'
        });

    }

    updateRound = () => {

        window.scrollTo({
              top: 0,
              behavior: 'smooth'
        });

        let behavior_var = "system_behavior_prediction_"+localStorage.getItem("round")

        localStorage.setItem(behavior_var,this.state.system_behavior_prediction)

        let round = parseInt(localStorage.getItem("round"))

        if (round < 10){
            round = round +1
        }

        round = String(round)

        localStorage.setItem("round",round)

        axios.get('/api/patient', {params: {'round': round}})
          .then((response) => {
              localStorage.setItem("round", round)
              this.setState({"patient": response.data})
          })
          .catch((err) => {
              console.log('Error occurred' + err);
          });

    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}></h4>
                            <p>
                                {
                                    (parseInt(localStorage.getItem("round")) === 1) &&
                                    "Below you are presented the first patient."
                                }
                                {
                                    (parseInt(localStorage.getItem("round")) > 1)  &&
                                    <b>Below you are presented the next patient.</b>
                                }
                            </p>
                            <hr/>
                            <p>
                                <ul>
                                    {
                                        window.screen.width >= 1280 &&
                                        <li>For further information, hover over the characteristic</li>
                                    }
                                    {
                                        window.screen.width < 1280 &&
                                        <li>For further information, click on the characteristic</li>
                                    }
                                    <li>The 5 most important characteristics are colored from yellow (important) to red (very important)</li>
                                </ul>
                            </p>
                            <hr/>
                            <h6>
                                Demographics
                            </h6>
                            <p>
                                <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                    <tbody>
                                        <tr>
                                            <th>Sex</th>
                                           <td>{this.state.translation["Sex"][this.state.patient["Sex"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Age</th>
                                            <td>{this.state.patient.Age}</td>
                                        </tr>
                                        <tr>
                                            <th>Family history of heart disease</th>
                                           <td>{this.state.translation["famhist"][this.state.patient["Family history of heart disease"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Number of years smoking</th>
                                            <td>{this.state.patient['Smoking history']}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Exercise test results
                                </h6>
                                <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                    <tbody>
                                        <Tooltip anchorId="maxHeartRate" content="A common indicator for a healthy heart" place="top"/>
                                        <Tooltip anchorId="chestPainExercise" content="A common indicator for a healthy heart" place="top"/>
                                        <Tooltip anchorId="cloggedVessels" content="Are associated with a higher risk of a myocardic infarct" place="top"/>
                                        <tr>
                                            <th><p id="maxHeartRate" style={{color:"#ffb700"}}>Maximum heart rate reached sufficient (for age group and gender)</p></th>
                                            <td>{this.state.translation["thalach_flg"][this.state.patient["Maximum heart rate at Exercise Test sufficient"]]}</td>
                                        </tr>
                                        <tr>
                                            <th><p id="chestPainExercise" style={{color:"#ffc700"}}>Feeling chest pain at exercise test</p></th>
                                            <td>{this.state.translation["exang"][this.state.patient["Feeling chest pain at exercise test"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Examination results
                                </h6>
                                <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                    <tbody>
                                        <tr>
                                            <th><p id="cloggedVessels" style={{color:"#ff8600"}}>Number of vessels clogged</p></th>
                                            <td>{this.state.patient["Number of vessels clogged"]}</td>
                                        </tr>
                                        <tr>
                                            <th><p style={{color:"#FF0000"}}>Heart scan result </p></th>
                                            <td>{this.state.translation["thal"][this.state.patient["Heart scan result"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Measurements
                                </h6>
                                <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                    <tbody>
                                        <tr>
                                            <th>Blood pressure in rest state above average?</th>
                                            <td>{this.state.translation["Blood pressure in rest state"][this.state.patient["Blood pressure in rest state"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Heart rate in rest state above average?</th>
                                            <td>{this.state.translation["Heart rate in rest state"][this.state.patient["Heart rate in rest state"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Blood sugar level above normal level?</th>
                                            <td>{this.state.translation["Blood sugar level"][this.state.patient["Blood sugar level"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Cholesterol level above average?</th>
                                            <td>{this.state.translation["Cholesterol level"][this.state.patient["Cholesterol level"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row style={{'paddingBottom': "1%"}}>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <p style={{'maxWidth': "450px"}}>
                                    Now, you are asked to make a guess about <i> what the algorithm will give as an advice</i> (not your own prediction!) of the patients health.
                                    Will the algorithms advice be that the patient is at risk of incurring a heart disease? And please give an indicator on how confident you
                                    are in this decision.
                                </p>
                                {
                                    parseInt(localStorage.getItem("round")) === 6  &&
                                    <Test onOptionSelect={selectedOptions => this.setState({"system_behavior_prediction":JSON.stringify(selectedOptions["0"])})}>
                                      <QuestionGroup questionNumber={0}>
                                        <Option value="yes">Risk</Option>
                                        <Option value="no">No risk</Option>
                                      </QuestionGroup>
                                    </Test>
                                }
                                {
                                    parseInt(localStorage.getItem("round")) === 7  &&
                                    <Test onOptionSelect={selectedOptions => this.setState({"system_behavior_prediction":JSON.stringify(selectedOptions["1"])})}>
                                      <QuestionGroup questionNumber={1}>
                                        <Option value="yes">Risk</Option>
                                        <Option value="no">No risk</Option>
                                      </QuestionGroup>
                                    </Test>
                                }
                                {
                                    parseInt(localStorage.getItem("round")) === 8  &&
                                    <Test onOptionSelect={selectedOptions => this.setState({"system_behavior_prediction":JSON.stringify(selectedOptions["2"])})}>
                                      <QuestionGroup questionNumber={2}>
                                        <Option value="yes">Risk</Option>
                                        <Option value="no">No risk</Option>
                                      </QuestionGroup>
                                    </Test>
                                }
                                {
                                    parseInt(localStorage.getItem("round")) === 9  &&
                                    <Test onOptionSelect={selectedOptions => this.setState({"system_behavior_prediction":JSON.stringify(selectedOptions["3"])})}>
                                      <QuestionGroup questionNumber={3}>
                                        <Option value="yes">Risk</Option>
                                        <Option value="no">No risk</Option>
                                      </QuestionGroup>
                                    </Test>
                                }
                                {
                                    parseInt(localStorage.getItem("round")) === 10  &&
                                    <Test onOptionSelect={selectedOptions => this.setState({"system_behavior_prediction":JSON.stringify(selectedOptions["4"])})}>
                                      <QuestionGroup questionNumber={4}>
                                        <Option value="yes">Risk</Option>
                                        <Option value="no">No risk</Option>
                                      </QuestionGroup>
                                    </Test>
                                }
                                <hr/>
                                <p>
                                    And please indicate to which degree you agree with the statement below:
                                </p>
                                {
                                    <Row>
                                        <Col>
                                            <p></p>
                                        </Col>
                                        <Col>
                                            <Likert {...this.state.r1} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                                        </Col>
                                    </Row>
                                }
                                <hr/>
                            </Col>
                        </Row>
                        <hr/>
                        {
                            parseInt(localStorage.getItem("round")) < 10 &&
                            <Link to={'/break_test'}>
                                <Button variant="outline-primary" onClick={this.updateRound}>Go to next</Button>
                            </Link>
                        }
                        {
                            parseInt(localStorage.getItem("round")) === 10 &&
                            <Link to={'/conclude_study'}>
                                <Button variant="outline-primary" onClick={this.updateRound}>Proceed with study</Button>
                            </Link>
                        }
                    </Row>
                </Container>
            </>
        )
    }
}

export default Patient_Presentation;