import React from 'react';
import {Container, Row, Col, Navbar, Button, Alert} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Global_Expectation extends React.Component {

     constructor(props) {
         super(props);
         this.state = {
             loaded: false,
             importancy: "no"
         }
     }

    componentDidMount() {

         localStorage.setItem("feature",{
                                            "1": "Maximum heart rate at Exercise Test sufficient",
                                            "2": "Number of vessels clogged",
                                            "3": "Heart scan result",
                                            "4": "Feeling chest pain at exercise test"
                                        }[localStorage.getItem("round")])

        this.setState({loaded:true})

    }

    setImportancy = () => {
        if(this.state.importancy === "no"){
            this.setState({"importancy":"yes"})
            localStorage.setItem("importancy_"+localStorage.getItem("round"),"yes")
        } else {
            this.setState({"importancy":"no"})
            localStorage.setItem("importancy_"+localStorage.getItem("round"),"no")
        }
    }

    setAnswer = (answer) => {
        localStorage.setItem("expectation_"+localStorage.getItem("round"),answer)
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                        <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <h4 style={{'paddingBottom': "1%", 'paddingTop': '2%'}}>
                                    Please have a look at the situation below:
                                </h4>
                                <hr/>
                                {
                                    this.state.loaded === true && localStorage.getItem("feature") === "Maximum heart rate at Exercise Test sufficient" &&
                                    <Alert key={"primary"} variant={"primary"}>
                                             <p style={{'maxWidth':'550px'}}>
                                                A patient has reached a sufficient maximum heart rate at an exercise test.
                                            </p>
                                    </Alert>
                                }
                                {
                                    this.state.loaded === true && localStorage.getItem("feature") === "Number of vessels clogged" &&
                                    <Alert key={"primary"} variant={"primary"}>
                                        <p style={{'maxWidth':'550px'}}>
                                            A medical examination has shown that a patient has a higher number of clogged ("verstopft") vessels (which are
                                            responsible for carrying blood to the heart) than another examined patient.
                                        </p>
                                    </Alert>
                                }
                                {
                                    this.state.loaded === true && localStorage.getItem("feature") === "Heart scan result" &&
                                    <Alert key={"primary"} variant={"primary"}>
                                        <p style={{'maxWidth':'550px'}}>
                                            A heart scan examination has shown that a patient had a prior heart defect has been fixed,
                                            as opposed to another examined patient, which did not have any prior heart defect.
                                        </p>
                                    </Alert>
                                }
                                {
                                    this.state.loaded === true && localStorage.getItem("feature") === "Feeling chest pain at exercise test" &&
                                    <Alert key={"primary"} variant={"primary"}>
                                        <p style={{'maxWidth':'550px'}}>
                                           A patient has experienced chest pain at an exercise test, where an exhausting activity had to be performed.
                                        </p>
                                    </Alert>
                                }
                                <Alert key={"secondary"} variant={"secondary"}>
                                    <p style={{"maxWidth":'550px'}}>
                                        What is your intuition, should this fact <b>1)</b> be considered by the algorithm and <b>2)</b> if yes, should it be seen
                                        as an indicator that this patient is at risk for incurring a heart disease?
                                    </p>
                                </Alert>
                                <hr/>
                                <Row>
                                    <div>
                                        <p><b>1)</b></p>
                                         <form>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" id="consent" onChange={this.setImportancy}/>
                                                    </td>
                                                    <td>
                                                        <label style={{"paddingLeft":"1%"}} htmlFor="consent"> I think that this is an important fact that the algorithm should consider.</label>
                                                    </td>
                                                </tr>
                                            </table>
                                        </form>
                                        <hr/>
                                        <p><b>2)</b></p>
                                        {
                                            <div style={{"maxWidth":"540px"}}>
                                                <Row>
                                                    <Col>
                                                        <Link to={'/global_explanation'} style={{"paddingRight":"1%","paddingBottom":"1%"}}>
                                                            <Button variant="primary" onClick={() => this.setAnswer('1')}>Indicator for heart disease risk</Button>
                                                        </Link>
                                                    </Col>
                                                    <Col>
                                                        <Link to={'/global_explanation'}>
                                                            <Button variant="primary" onClick={() => this.setAnswer('-1')}>Indicator for no heart disease risk</Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                </Container>
            </>
        )
    }
}

export default Global_Expectation;