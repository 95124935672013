import React, { useState, useEffect }  from 'react';
import 'react-chat-widget/lib/styles.css';
import {Col, Container, Row, Alert, Table} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {TextField, Button} from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import {Tooltip} from "react-tooltip";

export default class Counterfactual_Feedback_Learning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pairs:[],
            patient:"",
            cf_status:"not started",
            most_frequent_changes:"",
            user_feedback:"",
            current_counterfactual_state:"",
            nextRound:"false",
            loading:false,
            feedback:false,
            showButtons:false,
            placeholders: ['e.g. type "the age should be lower"','e.g. type "the smoking history should be longer"','e.g. the number of clogged vessels ' +
            'should be higher'],
            currentPlaceholder: '',
            currentIndex: 0,
            translation_index: {
                0:"Heart scan result",
                1:"Maximum heart rate at exercise test sufficient",
                2:"Number of vessels clogged",
                3:"Feeling chest pain at exercise test",
                4:"Sex",
                5:"Blood pressure in rest state",
                6:"Heart rate in rest state",
                7:"Age",
                8:"Smoking history",
                9:"Blood sugar level",
                10:"Cholesterol level",
                11:"Family history of heart disease"
            },
            translation_direction: {
                0:{
                    "-1":"more healthy",
                    "1":"less healthy"
                },
                1:{
                    "-1":"no",
                    "1":"yes"
                },
                2:{
                    "-1":"less",
                    "1":"more"
                },
                3:{
                    "-1":"no",
                    "1":"yes"
                },
                4:{
                    "-1":"female",
                    "1":"male"
                },
                5:{
                    "-1":"not above average",
                    "1":"above average"
                },
                6:{
                    "-1":"not above average",
                    "1":"above average"
                },
                7:{
                    "-1":"lower",
                    "1":"higher"
                },
                8:{
                    "-1":"shorter",
                    "1":"longer"
                },
                9:{
                    "-1":"not above average",
                    "1":"above average"
                },
                10:{
                    "-1":"not above average",
                    "1":"above average"
                },
                11:{
                    "-1":"no",
                    "1":"yes"
                }
            },
            translation: {
                "0":"no risk",
                "1":"risk",
                "Sex":{
                    0:"female",
                    1:"male"
                },
                "thalach_flg": {
                    0:"no",
                    1:"yes"
                },
                "famhist":{
                    0:"no",
                    1:"yes"
                },
                "exang":{
                    0:"no",
                    1:"yes"
                },
                "thal":{
                    0:"no defect",
                    1:"fixed defect detected",
                    2:"reversible defect detected"
                },
                "Chest pain":{
                    0:"no",
                    1:"yes",
                },
                "Blood sugar level":{
                    0:"no",
                    1:"yes"
                },
                "Blood pressure in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Heart rate in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Cholesterol level":{
                    0:"no",
                    1:"yes"
                }
            },
            translation_opposite:{
                "no risk": "risk",
                "risk":"no risk"
            }
        }
    }

    typewriter = () => {

        let { placeholders, currentIndex } = this.state;

        if (currentIndex >= placeholders.length) {
          currentIndex = 0;
        }

        let placeholder = placeholders[currentIndex];

        let i = 0;

        this.interval = setInterval(() => {
          if (i < placeholder.length) {
            this.setState({
              currentPlaceholder: this.state.currentPlaceholder + placeholder[i],
            });
            i++;
          } else {
            clearInterval(this.interval);
            setTimeout(() => {
              this.setState({
                currentPlaceholder: '',
                currentIndex: currentIndex + 1,
              });
              this.typewriter();
            }, 3000);
          }
        }, 100);
  };

    getCFUpdate (feature, direction, event){

        this.setState({loading:true})

        let user_feedback_text = localStorage.getItem("user_feedback")

        localStorage.setItem("counterfactual_learning_feedback",
            localStorage.getItem("counterfactual_learning_feedback")+String(localStorage.getItem("round"))+"_feedback_text:"+user_feedback_text+"_button_feedback:"+feature+"_direction:"+direction)

        let index = parseInt(localStorage.getItem("index"))

        let round = localStorage.getItem("round")

        localStorage.removeItem("user_feedback")

        event.preventDefault();

        axios.get('/api/counterfactualDevelopment',
            {
                params:
                    {
                    "session_id":localStorage.getItem("session_id"),
                    "prior_algorithm_decision":localStorage.getItem(round+"_"+"prediction_cf_feedback"),
                    "user_feedback_buttons_feature":feature,
                    "user_feedback_buttons_direction":direction,
                    "user_feedback_text": user_feedback_text,
                    "current_counterfactual_state": this.state.current_counterfactual_state,
                    "index":index,
                    }
            }
        )
          .then((response) => {
              console.log(response.data["current_counterfactual_state"])
              localStorage.setItem("current_counterfactual_state",response.data["current_counterfactual_state"])
              localStorage.setItem("most_frequent_changes",localStorage.getItem("most_frequent_changes")+"//"+response.data["most_frequent_changes"]+"//")
              if(response.data["state"]==="not finished"){
                  this.setState({
                    cf_status:"not finished",
                    current_counterfactual_state:response.data["current_counterfactual_state"],
                    most_frequent_changes:response.data["most_frequent_changes"]
                  })
              } else {
                  this.setState({
                      cf_status:"finished",
                      current_counterfactual_state:response.data["current_counterfactual_state"]
                  })
              }
              console.log("state set")
          })
          .catch((err) => {
              console.log("tasda");
          }).finally(() => {
            this.setState({showButtons:true,
            loading:false})
        });
        localStorage.removeItem("nextRound")
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setNotConvinced(){
        let round = parseInt(localStorage.getItem("round"))
        localStorage.setItem("notConvinced",localStorage.getItem("notConvinced")+String(round))
    }

    componentDidMount() {

        let index = {1:31, 2:147, 3:79, 4:90, 5:91, 6:142, 7:140, 8:144, 9:66, 10:48}

        if(localStorage.getItem("nextRound") === null){
            if(this.state.cf_status === "not started"){
                localStorage.setItem("round",new URLSearchParams(window.location.search).get("round"))
            }
        } else {
            this.setState({
                cf_status:"not started",
            })
        }

        let round = parseInt(localStorage.getItem("round"))

        console.log(round)

        localStorage.setItem("index",index[round])

        this.typewriter();

        this.setState({loading: true})

        if(localStorage.getItem(round+"_"+"prediction_cf_feedback") === null){
            axios.get('/api/prediction', {params: {'index': index[round], 'session_id':localStorage.getItem('session_id')}})
            .then((response) => {
                localStorage.setItem(round+"_"+"prediction_cf_feedback", response.data["Prediction"]);
            })
            .catch((err) => {
                console.log('Error occurred' + err);
            })
        }

        axios.get('/api/patient', {params: {'index': index[round]}})
            .then((response) => {
                this.setState({"patient": response.data})
            })
            .catch((err) => {
                console.log('Error occurred' + err);
            }).finally(() => {
                this.setState({loading: false})
            });;
    }

    render() {

        const { cf_status, showButtons } = this.state;

        return (
            <div>
                <Container>
                    <Col>
                    {
                        this.state.loading === true &&
                        <div style={{"paddingTop":"10%"}}>
                            {
                                localStorage.getItem("user_feedback") === null &&
                                <p>Please wait shortly while the page is loaded.</p>
                            }
                            {
                                localStorage.getItem("user_feedback") !== null &&
                                <p>Please wait shortly while your feedback is processed. You will receive an updated explanation.</p>
                            }
                            <ClipLoader
                              size={"10vh"}
                              color={"#123abc"}
                              loading={this.state.loading}
                              speedMultiplier={1.5}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                        </div>

                    }
                    {
                        this.state.loading === false && this.state.cf_status !== "finished" && this.state.most_frequent_changes !== "" && !("0" in this.state.most_frequent_changes) &&
                                <div>
                                    <p>We couldn't find further possible changes to flip the algorithms advice. Please continue.</p>
                                                {
                                                    localStorage.getItem("round") === "5" &&
                                                    <Row>
                                                        <Link to={'/introduction_test'}>
                                                            <Button variant="contained">Continue</Button>
                                                        </Link>
                                                    </Row>
                                                }
                                                {
                                                    localStorage.getItem("round") !== "5" &&
                                                    <Row>
                                                        <Link to={'/confirmation_learning'}>
                                                            <Button variant="contained">Continue</Button>
                                                        </Link>
                                                    </Row>
                                                }
                                </div>
                    }
                    {this.state.loading === false && !(this.state.cf_status !== "finished" && this.state.most_frequent_changes !== "" && !("0" in this.state.most_frequent_changes)) &&
                                    <div style={{'maxWidth':'550px','paddingTop':"5%"}}>
                                        <Row>
                                            {
                                                this.state.current_counterfactual_state !== "" &&
                                                <Alert key={"danger"} variant={"danger"}>
                                                    <b>These are the currently supplied changes:</b>
                                                    {
                                                        <ul>
                                                            {JSON.parse(this.state.current_counterfactual_state).map((item, index) => (
                                                                <li key={index}>
                                                                    {this.state.translation_index[item[0]]} ({this.state.translation_direction[item[0]][item[1]]})
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </Alert>

                                            }
                                        </Row>
                                        <Row>
                                            {
                                                // TODO: Fall abfangen, dass jemand vllt blöde Kombis ausgewählt hat -> schickt zurück zum Start der Runde
                                                this.state.cf_status !== "not started" &&
                                                <Alert key={"info"} variant={"info"}>
                                                    {
                                                        this.state.cf_status === "not finished" &&
                                                        <p>
                                                            These changes are currently <b>not sufficient to change the advice
                                                            of the algorithm</b>. Please <b>first have a look at the patients data again</b>,
                                                            and <b>then scroll to the bottom of the page</b> and <b>select one of the buttons for
                                                            further changes</b> (the value in brackets always represents the value to which the characteristic is being changed).
                                                        </p>
                                                    }
                                                    {
                                                        this.state.cf_status === "finished" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "risk" && localStorage.getItem("round")==="5" &&
                                                        <p>
                                                            These changes to the patient are sufficient to change the advice of the algorithm
                                                            for this patient to being <b>not at risk</b>. You have finished and can now proceed with the study.
                                                        </p>
                                                    }
                                                    {
                                                        this.state.cf_status === "finished" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "no risk" && localStorage.getItem("round")==="5" &&
                                                        <p>
                                                            These changes to the patient are sufficient to change the advice of the algorithm
                                                            for this patient to <b>being at risk</b>. You have finished and can now proceed with the study.
                                                        </p>
                                                    }
                                                    {
                                                        this.state.cf_status === "finished" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "risk" && localStorage.getItem("round")!=="5" &&
                                                        <p>
                                                            These changes to the patient are sufficient to change the advice of the algorithm
                                                            for this patient to being <b>not at risk</b>. You have finished and can now proceed with the next patient.
                                                        </p>
                                                    }
                                                    {
                                                        this.state.cf_status === "finished" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "no risk" && localStorage.getItem("round")!=="5" &&
                                                        <p>
                                                            These changes to the patient are sufficient to change the advice of the algorithm
                                                            for this patient to <b>being at risk</b>. You have finished and can now proceed with the next patient.
                                                        </p>
                                                    }
                                                </Alert>
                                            }
                                        </Row>
                                        <Row>
                                            {
                                                !showButtons &&
                                                <Alert key={"danger"} variant={"danger"}>
                                                    <p>
                                                        Below, you have to <b>write your feedback on which characteristics of the patients
                                                        should be different</b> in order that <b>the algorithm
                                                        gives the opposite advice</b>. (e.g. "the smoking history should be lower")
                                                    </p>
                                                    <p>
                                                        After submitting, you will receive further guidance on how to proceed.
                                                    </p>
                                                </Alert>
                                            }
                                        </Row>
                                        {
                                            this.state.cf_status === "finished" &&
                                            <div>
                                                <hr/>
                                                {
                                                    this.state.cf_status === "finished" && localStorage.getItem("round") === "5" &&
                                                    <Row>
                                                        <Link to={'/introduction_test'}>
                                                            <Button variant="contained">Continue</Button>
                                                        </Link>
                                                    </Row>
                                                }
                                                {
                                                    this.state.cf_status === "finished" && localStorage.getItem("round") !== "5" &&
                                                    <Row>
                                                        <Link to={'/confirmation_learning'}>
                                                            <Button variant="contained">Continue</Button>
                                                        </Link>
                                                    </Row>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.cf_status !== "finished" &&
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}></h4>
                                                        <p>
                                                            {
                                                                (parseInt(localStorage.getItem("round")) === 1) &&
                                                                "Below you are presented the first patient."
                                                            }
                                                            {
                                                                (parseInt(localStorage.getItem("round")) > 1)  &&
                                                                <b>Below you are presented the next patient.</b>
                                                            }
                                                        </p>
                                                        <hr/>
                                                        <p>
                                                            <ul>
                                                                {
                                                                    window.screen.width >= 1280 &&
                                                                    <li>For further information, hover over the characteristic</li>
                                                                }
                                                                {
                                                                    window.screen.width < 1280 &&
                                                                    <li>For further information, click on the characteristic</li>
                                                                }
                                                                <li>The 5 most important characteristics are colored from yellow (important) to red (very important)</li>
                                                            </ul>
                                                        </p>
                                                        <hr/>
                                                        <h6>
                                                            Demographics
                                                        </h6>
                                                        <p>
                                                            <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                                                <tbody>
                                                                <tr>
                                                                    <th>Sex</th>
                                                                    <td>{this.state.translation["Sex"][this.state.patient["Sex"]]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Age</th>
                                                                    <td>{this.state.patient.Age}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Family history of heart disease</th>
                                                                    <td>{this.state.translation["famhist"][this.state.patient["Family history of heart disease"]]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Number of years smoking</th>
                                                                    <td>{this.state.patient['Smoking history']}</td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                            <h6>
                                                                Exercise test results
                                                            </h6>
                                                            <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                                                <tbody>
                                                                <Tooltip anchorId="maxHeartRate" content="A common indicator for a healthy heart" place="top"/>
                                                                <Tooltip anchorId="chestPainExercise" content="A common indicator for a healthy heart" place="top"/>
                                                                <Tooltip anchorId="cloggedVessels" content="Are associated with a higher risk of a myocardic infarct" place="top"/>
                                                                <tr>
                                                                    <th><p id="maxHeartRate" style={{color:"#ffb700"}}>Maximum heart rate reached sufficient (for age group and gender)</p></th>
                                                                    <td>{this.state.translation["thalach_flg"][this.state.patient["Maximum heart rate at Exercise Test sufficient"]]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><p id="chestPainExercise" style={{color:"#ffc700"}}>Feeling chest pain at exercise test</p></th>
                                                                    <td>{this.state.translation["exang"][this.state.patient["Feeling chest pain at exercise test"]]}</td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                            <h6>
                                                                Examination results
                                                            </h6>
                                                            <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                                                <tbody>
                                                                <tr>
                                                                    <th><p id="cloggedVessels" style={{color:"#ff8600"}}>Number of vessels clogged</p></th>
                                                                    <td>{this.state.patient["Number of vessels clogged"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><p style={{color:"#FF0000"}}>Heart scan result </p></th>
                                                                    <td>{this.state.translation["thal"][this.state.patient["Heart scan result"]]}</td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                            <h6>
                                                                Measurements
                                                            </h6>
                                                            <Table striped bordered hover style={{ maxWidth: "350px" }}>
                                                                <tbody>
                                                                <tr>
                                                                    <th>Blood pressure in rest state above average?</th>
                                                                    <td>{this.state.translation["Blood pressure in rest state"][this.state.patient["Blood pressure in rest state"]]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Heart rate in rest state above average?</th>
                                                                    <td>{this.state.translation["Heart rate in rest state"][this.state.patient["Heart rate in rest state"]]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Blood sugar level above normal level?</th>
                                                                    <td>{this.state.translation["Blood sugar level"][this.state.patient["Blood sugar level"]]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Cholesterol level above average?</th>
                                                                    <td>{this.state.translation["Cholesterol level"][this.state.patient["Cholesterol level"]]}</td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={{'paddingTop':'1%'}}>
                                                    <hr/>
                                                    <Alert key={"primary"} variant={"primary"}>
                                                        <p>
                                                            The <b>algorithm's advice</b> is that the patient is being at <b>{this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")]}.</b>
                                                        </p>
                                                    </Alert>
                                                </Row>
                                                <Row>
                                                    <hr/>
                                                    {
                                                        <Alert>
                                                            {
                                                                this.state.cf_status !== "not started" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "risk" &&
                                                                <p>
                                                                    What do <b>you think</b> should be the <b>next change to the patient</b>, so that <b>the algorithm
                                                                    gives the advice</b> that the patient is at <b>no risk</b>? <b>Choose one of the options below:</b>
                                                                </p>
                                                            }
                                                            {
                                                                this.state.cf_status !== "not started" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "no risk" &&
                                                                <p>
                                                                    What do you think should be the next change to the patient, so that the algorithm
                                                                    gives the advice that the patient is at <b>risk</b>? <b>Choose one of the options below:</b>
                                                                </p>
                                                            }
                                                            {
                                                                this.state.cf_status === "not started" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "risk" &&
                                                                <p>
                                                                    What do <b>you think</b> should be the <b>first change to the patient</b>, so that <b>the algorithm
                                                                    gives the advice</b> that the patient is at <b>no risk</b>? <b>Type it in the text field below.</b>
                                                                </p>
                                                            }
                                                            {
                                                                this.state.cf_status === "not started" && this.state.translation[localStorage.getItem(localStorage.getItem("round")+"_"+"prediction_cf_feedback")] === "no risk" &&
                                                                <p>
                                                                    What do you think should be the first change to the patient, so that the algorithm
                                                                    gives the advice that the patient is at <b>risk</b>? <b>Type it in the text field below.</b>
                                                                </p>
                                                            }
                                                        </Alert>
                                                    }
                                                </Row>
                                                {
                                                    this.state.cf_status !== "finished" &&
                                                    <div>
                                                        {
                                                            this.state.most_frequent_changes !== "" &&
                                                            <Row id="quick_buttons" style={{"paddingBottom":"2%"}}>
                                                                {
                                                                    "0" in this.state.most_frequent_changes &&
                                                                    <Button variant="contained" color="success" onClick={(event) => this.getCFUpdate(this.state.most_frequent_changes["0"]["feature"],this.state.most_frequent_changes["0"]["direction"],event)}>
                                                                        <p>{this.state.most_frequent_changes["0"]["feature_name"]} <b>({this.state.translation_direction[this.state.most_frequent_changes["0"]["feature"]][this.state.most_frequent_changes["0"]["direction"]]})</b></p>
                                                                    </Button>
                                                                }
                                                            </Row>
                                                        }
                                                        {
                                                            this.state.most_frequent_changes !== "" &&
                                                            <Row id="quick_buttons" style={{"paddingBottom":"2%"}}>
                                                                {
                                                                    "1" in this.state.most_frequent_changes &&
                                                                    <Button variant="contained" color="success" onClick={(event) => this.getCFUpdate(this.state.most_frequent_changes["1"]["feature"],this.state.most_frequent_changes["1"]["direction"],event)}>
                                                                        <p>{this.state.most_frequent_changes["1"]["feature_name"]} <b>({this.state.translation_direction[this.state.most_frequent_changes["1"]["feature"]][this.state.most_frequent_changes["1"]["direction"]]})</b></p>
                                                                    </Button>
                                                                }
                                                            </Row>
                                                        }
                                                        {
                                                            this.state.most_frequent_changes !== "" &&
                                                            <Row id="quick_buttons" style={{"paddingBottom":"2%"}}>
                                                                {
                                                                    "2" in this.state.most_frequent_changes &&
                                                                    <Button variant="contained" color="success" onClick={(event) => this.getCFUpdate(this.state.most_frequent_changes["2"]["feature"],this.state.most_frequent_changes["2"]["direction"],event)}>
                                                                        <p>{this.state.most_frequent_changes["2"]["feature_name"]} <b>({this.state.translation_direction[this.state.most_frequent_changes["2"]["feature"]][this.state.most_frequent_changes["2"]["direction"]]})</b></p>
                                                                    </Button>
                                                                }
                                                            </Row>
                                                        }
                                                        {
                                                            this.state.most_frequent_changes !== "" &&
                                                            <Row id="quick_buttons" style={{"paddingBottom":"2%"}}>
                                                                <p>Not convinced of any of these? Then click here to continue to the next patient:</p>
                                                                {
                                                                    <Row>
                                                                        <Link to={'/confirmation_learning'}>
                                                                            <Button variant="contained" onClick={this.setNotConvinced}>Continue</Button>
                                                                        </Link>
                                                                    </Row>
                                                                }
                                                            </Row>
                                                        }
                                                    </div>
                                                }
                                                <Row>
                                                    {
                                                        this.state.cf_status === "not started" &&
                                                        <div>
                                                            <Row>
                                                                <Row id="quick_buttons">
                                                                    {
                                                                        <TextField id="feedbackInputTextField"
                                                                                   onChange={
                                                                                       event => localStorage.setItem("user_feedback",event.target.value)
                                                                                   } variant="outlined" placeholder={this.state.currentPlaceholder}
                                                                        />
                                                                    }
                                                                </Row>
                                                            </Row>
                                                            <hr/>
                                                            <Button onClick={(event) => this.getCFUpdate("","",event)} variant="contained" color="success">
                                                                Send
                                                            </Button>
                                                        </div>
                                                    }
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                    }
                                </Col>
                </Container>
            </div>
        )
    }
}