import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Introduction_Counterfactual_Feedback extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            <h4 style={{'paddingBottom': "2%", 'paddingTop': "2%"}}>In the next step, you will be asked to simulate the algorithms behavior.</h4>
                            <h6>(Approximated duration: 3-4 minutes)</h6>
                            <p>
                                First, you will be presented a new patient and the algorithm's advice.
                            </p>
                            <p>
                                Then, you will be asked to type into a textfield which characteristic of the patient you
                                think should be changed first, in order for the algorithm to change its advice.
                                (e.g. "the smoking history should be lower")
                            </p>
                            <p>
                                This process will be repeated until you found a complete set of changes. 'Complete set'
                                here refers to a set of changes that, if they would all be applied to the patient, lead
                                to an algorithm advice in the opposite category. (e.g. prior was "risk", then "no risk").
                                You will receive recommendations for suitable changes.
                            </p>
                            <Link to={'/counterfactual_feedback'}>
                                <Button variant="primary">Proceed with study</Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

}

export default Introduction_Counterfactual_Feedback;