import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {Tooltip} from 'react-tooltip'
import DOMPurify from "dompurify";
import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

class Patient_Familiarization extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            progress: 0,
            loading: false,
            explanations: "",
            patient: {},
            groundtruth: {},
            prediction: {},
            importance: {
                'Heart scan result': 'success',
                'Chest pain': 'error',
                'Number of vessels clogged': 'warning',
                 'Maximum heart rate at Exercise Test sufficient (flag)': 'info',
                 'Feeling chest pain at exercise test': 'secondary'
            },
            translation: {
                "Sex":{
                    0:"female",
                    1:"male"
                },
                "thalach_flg": {
                    0:"no",
                    1:"yes"
                },
                "famhist":{
                    0:"no",
                    1:"yes"
                },
                "exang":{
                    0:"no",
                    1:"yes"
                },
                "thal":{
                    0:"no defect",
                    1:"fixed defect detected",
                    2:"unfixed defect detected"
                },
                "Chest pain":{
                    0:"no",
                    1:"yes"
                },
                "Blood sugar level":{
                    0:"no",
                    1:"yes"
                },
                "Blood pressure in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Heart rate in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Cholesterol level":{
                    0:"no",
                    1:"yes"
                }
            }
        }
    }

    componentDidMount() {

            if(localStorage.getItem("group")!=="A" && localStorage.getItem("explanations") === "yes"){
                this.intervalId = setInterval(() => {
                  this.setState(prevState => ({
                    progress: prevState.progress + 5
                  }));
                }, 900);}

        let start = (new URLSearchParams(window.location.search)).get("start")

        // TODO: Ladeelement einbauen, bis CF geladen ist

        // reset the round-counter for the counterfactual-added examples
        if(start === "yes"){
            localStorage.setItem("round","1")
        }

        this.refreshData()

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    refreshData(){

        this.setState({progress:0})

        let round = localStorage.getItem("round")
        let explanations_flag = localStorage.getItem("explanations")
        let group = localStorage.getItem("group")
        let omitted_features = localStorage.getItem("omitted_features")
        let sparsity_weight = localStorage.getItem("sparsity_weight")

        if(explanations_flag === "yes"){
            this.setState({"loading":true});
            axios.get('/api/counterfactual', {
                params: {
                    'session_id':localStorage.getItem('session_id'),
                    'group': group,
                    'round': round,
                    'sparsity_weight':sparsity_weight,
                    "omitted_features":omitted_features,
                    "start":"yes"
                }
            }
            )
                .then((response) => {
                    localStorage.setItem("counterfactual" + "_" + round, String(response.data["counterfactual"]))
                    this.setState({"explanations":"yes"});
                }).catch((err) => {
                    console.log('Error occurred' + err)}).finally(() => {this.setState({loading:false})})
        }

        axios.all([
            axios.get('/api/patient', {params: {'round': round}}),
            axios.get('/api/prediction', {params: {'round': round,'session_id':localStorage.getItem('session_id')}})]).
        then(axios.spread((patient_response, prediction_response ) => {
              localStorage.setItem("prediction"+"_"+round, prediction_response.data["Prediction"]);
              localStorage.setItem("groundtruth"+"_"+round,patient_response.data["num"]);
              this.setState({"patient":patient_response.data})
        }));

    }

    updateRound = () => {

        this.refreshData()

        window.scrollTo({
              top: 0,
              behavior: 'smooth'
        });

    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                        <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <h4 style={{'paddingBottom': "1%", 'paddingTop': '2%'}}></h4>
                                <p>
                                    {
                                        (parseInt(localStorage.getItem("round")) === 1 && this.state.explanations === "yes") &&
                                        <b>Below, you are presented the same patient as in the first round from the
                                            previous study phase. But now,
                                            you are additionally provided an explanation of the algorithm's advice
                                            shown below. Please have a look at it.</b>
                                    }
                                    {
                                        (parseInt(localStorage.getItem("round")) > 1 && this.state.explanations === "yes") &&
                                        <b>Below, you are presented the same patient as in
                                            round {localStorage.getItem("round")} from the previous study phase. But
                                            now,
                                            you are additionally provided an explanation of the algorithm's advice
                                            shown below. Please have a look at it.</b>
                                    }
                                    {
                                        (parseInt(localStorage.getItem("round")) === 1 && this.state.explanations !== "yes") &&
                                        <b>Below, you are presented the first patient.</b>
                                    }
                                    {
                                        (parseInt(localStorage.getItem("round")) > 1 && this.state.explanations !== "yes") &&
                                        "Below you are presented the next patient."
                                    }
                                </p>
                                <hr/>
                                <p>
                                    <ul>
                                        {
                                            window.screen.width >= 1280 &&
                                            <li>For further information, hover over the characteristic</li>
                                        }
                                        {
                                            window.screen.width < 1280 &&
                                            <li>For further information, click on the characteristic</li>
                                        }
                                        <li>The 5 most important characteristics are colored from yellow (important) to
                                            red (very important)
                                        </li>
                                    </ul>
                                </p>
                                <hr/>
                                <h6>
                                    Demographics
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <tr>
                                            <th>Sex</th>
                                            <td>{this.state.translation["Sex"][this.state.patient["Sex"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Age</th>
                                            <td>{this.state.patient.Age}</td>
                                        </tr>
                                        <tr>
                                            <th>Family history of heart disease</th>
                                            <td>{this.state.translation["famhist"][this.state.patient["Family history of heart disease"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Number of years smoking</th>
                                            <td>{this.state.patient["Smoking history"]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Exercise test results
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <Tooltip anchorId="maxHeartRate" content="Indicating a healthy heart throughput"
                                                 place="top"/>
                                        <Tooltip anchorId="chestPainExercise"
                                                 content="Indicating a limited heart output" place="top"/>
                                        <Tooltip anchorId="cloggedVessels"
                                                 content="A higher number is indicating a blocked blood supply to the heart"
                                                 place="top"/>
                                        <tr>
                                            <th><p id="maxHeartRate" style={{color: "#ffb700"}}>Maximum heart rate reached
                                                sufficient (for age group and gender)</p></th>
                                            <td>{this.state.translation["thalach_flg"][this.state.patient["Maximum heart rate at Exercise Test sufficient"]]}</td>
                                        </tr>
                                        <tr>
                                            <th><p id="chestPainExercise" style={{color: "#ffc700"}}>Feeling chest pain at
                                                exercise test</p></th>
                                            <td>{this.state.translation["exang"][this.state.patient["Feeling chest pain at exercise test"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Examination results
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <Tooltip anchorId="ChestPain"
                                                 content="A more general characteristic pointing to a medical problem"
                                                 place="top"/>
                                        <tr>
                                            <th><p id="cloggedVessels" style={{color: "#ff8600"}}>Number of vessels
                                                clogged</p></th>
                                            <td>{this.state.patient["Number of vessels clogged"]}</td>
                                        </tr>
                                        <tr>
                                            <th><p style={{color: "#FF0000"}}>Heart scan result</p></th>
                                            <td>{this.state.translation["thal"][this.state.patient["Heart scan result"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Measurements
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <tr>
                                            <th>Blood pressure in rest state above average?</th>
                                            <td>{this.state.translation["Blood pressure in rest state"][this.state.patient["Blood pressure in rest state"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Heart rate in rest state above average?</th>
                                            <td>{this.state.translation["Heart rate in rest state"][this.state.patient["Heart rate in rest state"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Blood sugar level above normal level?</th>
                                            <td>{this.state.translation["Blood sugar level"][this.state.patient["Blood sugar level"]]}</td>
                                        </tr>
                                        <tr>
                                            <th>Cholesterol level above average?</th>
                                            <td>{this.state.translation["Cholesterol level"][this.state.patient["Cholesterol level"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <hr/>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Link to={'/advice_familiarization'}>
                                <Button variant="outline-primary" onClick={this.updateRound}>Show Algorithm Advice</Button>
                            </Link>
                        </Row>
                </Container>
            </>
        )
    }
}

export default Patient_Familiarization;