import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import {Option, QuestionGroup, Test} from "react-multiple-choice";
import Likert from "react-likert-scale";

class Prediction_Familiarization extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            system_behavior_prediction:"",
            patient: {},
            answers: {},
            r1: {
            question: "I am confident in the decision.",
            responses: [
              { value: 1, text: "strongly agree" },
              { value: 2, text: "agree" },
              { value: 3, text: "neutral"},
              { value: 4, text: "disagree" },
              { value: 5, text: "strongly disagree" }
            ],
            onChange: val => {
              let answers = this.state.answers;
              answers["r1"] = val;
              this.setState({"answers":answers})
              localStorage.setItem("answer_confidence_familiarization"+localStorage.getItem("round"),JSON.stringify(this.state.answers))
            }
        }
        }
    }

    componentDidMount() {

        let round = localStorage.getItem("round")

        axios.get('/api/patient', {params: {'round': round}})
          .then((response) => {
              this.setState({"patient": response.data})
          })
          .catch((err) => {
              console.log('Error occurred' + err);
          });

        window.scrollTo({
              top: 0,
              behavior: 'smooth'
        });

    }

    updateRound = () => {
        let behavior_var = "system_behavior_prediction_"+localStorage.getItem("round")
        localStorage.setItem(behavior_var,this.state.system_behavior_prediction)
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row style={{'paddingBottom': "1%"}}>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <p>
                                    Now, you are asked to make a guess about the heart disease risk of the patient by yourself.
                                </p>
                                <p>
                                    <b>(If you need further information, you may go back to the patient page)</b>
                                </p>
                                {
                                    <Test onOptionSelect={selectedOptions => this.setState({"system_behavior_prediction":JSON.stringify(selectedOptions["0"])})}>
                                      <QuestionGroup questionNumber={0}>
                                        <Option value="yes">Risk</Option>
                                        <Option value="no">No risk</Option>
                                      </QuestionGroup>
                                    </Test>
                                }
                            </Col>
                        </Row>
                        <hr/>
                        <p>
                            And please indicate to which degree you agree with the statement below:
                        </p>
                        {
                            <Row>
                                <Col>
                                    <Likert {...this.state.r1} style={{'paddingBottom': "5%",'paddingTop':'5%'}}/>
                                </Col>
                            </Row>
                        }
                        <hr/>
                        {
                            this.state.system_behavior_prediction !== "" &&
                            <Link to={'/outcome_familiarization'}>
                                <Button variant="outline-primary" onClick={this.updateRound}>Continue</Button>
                            </Link>
                        }
                    </Row>
                </Container>
            </>
        )
    }

}

export default Prediction_Familiarization;