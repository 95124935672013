import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Form} from 'react-bootstrap';
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Introduction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            show_demographics:false,
            programming_knowledge_changed: false,
            domain_knowledge_changed: false,
            show_study_start:false,
            age: 0,
            programming_knowledge: 0,
            domain_knowledge: 0
        }
    }

    componentDidMount() {

        axios.all([axios.get('/api/groupassignment'),
            axios.get('/api/sessionid')]).then(axios.spread((group_response, session_id_response ) => {
              localStorage.setItem("group", group_response.data);
              localStorage.setItem("session_id", session_id_response.data);
              localStorage.setItem("round", "1")
        })).finally( () => {
            this.setState({loading: false})
            }
        );

    }

      handleProgrammingSliderChange = event => {
        this.setState({ programming_knowledge: event.target.value, programming_knowledge_changed: true});
        localStorage.setItem('programming_knowledge', String(event.target.value));
      };

    handleDomainSliderChange = event => {
        this.setState({ domain_knowledge: event.target.value , domain_knowledge_changed: true});
        localStorage.setItem('domain_knowledge', String(event.target.value));
      };

    syncLocalStorageState () {
        localStorage.setItem('domain_knowledge', String(this.state.domain_knowledge));
        localStorage.setItem('programming_knowledge', String(this.state.programming_knowledge));
    }

    handleAgeChange = event => {
        localStorage.setItem('age', String(event.target.value));
      };

    showDemographics = () => {
        this.setState({show_demographics:true})
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    {
                        localStorage.getItem("language")==="English" &&
                        <Row>
                        <Col>
                            <h1 style={{'paddingTop': "3%"}}>Welcome!</h1>
                            <h4 style={{'paddingBottom': "2%"}}>Thanks for your interest in my study.</h4>
                            <h6>(Approximated duration: 10-15 minutes)</h6>
                            <p style={{"maxWidth":"500px"}}>
                                <b>You are evaluating a simulated early-warning system for heart risk prediction
                                developed for my masters thesis in the field of Explainable Artificial Intelligence. All
                                of your given answers and information will be handled anonymously - so, your answers will
                                only be linked to an identifier like 'abc123475j12', not your real name or mail address.</b><br/><br/>
                                Please note that the information supplied here is <b>NOT medical advice</b>,
                                but rather an <b>experimental approach</b> using historical, real-world data.
                            </p>
                            <p>
                                More precise instructions are given to you in the respective sections.
                            </p>
                            <p>
                                In the end, you will have the possibility to win one of 20 Thalia-vouchers (20€ each). <br/>
                                The better your task performance is, the higher your chance of winning will be. So, good luck!
                            </p>
                            {
                                this.state.loading === false && this.state.show_demographics === false &&
                                <Row style={{'paddingBottom': "0.2%", 'maxWidth':'200px'}}>
                                    <Button variant="primary" onClick={this.showDemographics}>Proceed</Button>
                                </Row>
                            }
                            {
                                this.state.loading === false && this.state.show_demographics === true &&
                                <div>
                                    <br/>
                                    <hr/>
                                    <p>
                                        First, please fill in the information below.
                                    </p>
                                    <Form>
                                        <div style={{'maxWidth':"550px"}}>
                                            <Form.Group className="mb-3" controlId="formAge">
                                                <Form.Label>Age</Form.Label>
                                                <Form.Control type="number" min={0} max={99} placeholder="Enter your age" onChange={this.handleAgeChange}/>
                                            </Form.Group>
                                        </div>
                                        <div style={{'maxWidth':"550px"}}>
                                            <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        <Form.Label>On this scale from 0 (very low) to 100 (very high),
                                                        how would you rate your affinity to computers?</Form.Label>
                                                        <Form.Range min={0} max={100} value={this.state.programming_knowledge} onInput={this.handleProgrammingSliderChange}/>
                                                        <Form.Label>{this.state.programming_knowledge}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                            </Row>
                                        </div>
                                        <div style={{'maxWidth':"550px"}}>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Label>On this scale from 0 (no knowledge) to 100 (expert knowledge),
                                                where would you rate your knowledge level in the medical/biological
                                                domain?</Form.Label>
                                            <Form.Range min={0} max={100} value={this.state.domain_knowledge} onInput={this.handleDomainSliderChange}/>
                                            <Form.Label>{this.state.domain_knowledge}</Form.Label>
                                            </Form.Group>
                                        </div>
                                    </Form>
                                    <hr/>
                                </div>
                            }
                            {
                                this.state.show_demographics === true && this.state.domain_knowledge_changed && this.state.programming_knowledge_changed &&
                                <Link to={'/knowledge_evaluation'}>
                                    <Button variant="primary" onSubmit={this.syncLocalStorageState}>Start Study</Button>
                                </Link>
                            }
                        </Col>
                    </Row>
                    }
                    {
                        localStorage.getItem("language")==="German" &&
                        <Row>
                        <Col>
                            <h1 style={{'paddingTop': "3%"}}>Willkommen!</h1>
                            <h4 style={{'paddingBottom': "2%"}}>Vielen Dank für das Interesse an meiner Studie.</h4>
                            <h6>(Geschätzte Dauer: 10-15 Minuten)</h6>
                            <p style={{"maxWidth":"500px"}}>
                                <b>Sie bewerten ein simuliertes Frühwarnsystem zur Vorhersage des Herzrisikos, das ich im Rahmen meiner Masterarbeit auf dem Gebiet der erklärbaren künstlichen Intelligenz entwickelt habe. Alle von Ihnen gegebenen Antworten und Informationen werden anonym behandelt -
                                    Ihre Antworten werden also nur mit einer Kennung wie "abc123475j12" verknüpft, nicht mit Ihrem echten Namen oder Ihrer E-Mail-Adresse.</b><br/><br/>
                                    Bitte beachten Sie, dass es sich bei den hier bereitgestellten Informationen <b>NICHT um medizinische Ratschläge</b> handelt,
                                    sondern vielmehr um einen <b>experimentellen Ansatz</b> unter Verwendung historischer, realer Daten.
                            </p>
                            <p>
                                Genauere Anweisungen erhalten Sie in den jeweiligen Abschnitten.
                            </p>
                            <p>
                                Am Ende haben Sie die Möglichkeit, einen von 20 Thalia-Gutscheinen (je 20€) zu gewinnen. <br/>
                                Je besser Ihre Leistung ist, desto höher ist die Gewinnchance. Also, viel Glück!
                            </p>
                            {
                                this.state.loading === false && this.state.show_demographics === false &&
                                <Row style={{'paddingBottom': "0.2%", 'maxWidth':'200px'}}>
                                    <Button variant="primary" onClick={this.showDemographics}>Weiter</Button>
                                </Row>
                            }
                            {
                                this.state.loading === false && this.state.show_demographics === true &&
                                <div>
                                    <br/>
                                    <hr/>
                                    <p>
                                        Bitte füllen Sie zunächst die nachstehenden Informationen aus.
                                    </p>
                                    <Form>
                                        <div style={{'maxWidth':"550px"}}>
                                            <Form.Group className="mb-3" controlId="formAge">
                                                <Form.Label>Age</Form.Label>
                                                <Form.Control type="number" min={0} max={99} placeholder="Geben Sie Ihr Alter ein" onChange={this.handleAgeChange}/>
                                            </Form.Group>
                                        </div>
                                        <div style={{'maxWidth':"550px"}}>
                                            <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        <Form.Label>Auf dieser Skala von 0 (sehr gering) bis 100 (sehr hoch), wie würden Sie Ihre Affinität zu Computern einschätzen?</Form.Label>
                                                        <Form.Range min={0} max={100} value={this.state.programming_knowledge} onInput={this.handleProgrammingSliderChange}/>
                                                        <Form.Label>{this.state.programming_knowledge}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                            </Row>
                                        </div>
                                        <div style={{'maxWidth':"550px"}}>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Label>Auf dieser Skala von 0 (keine Kenntnisse) bis 100 (Expertenwissen),
                                                Wie hoch würden Sie Ihren Wissensstand im Bereich Medizin/Biologie einstufen?
                                                Bereich?</Form.Label>
                                            <Form.Range min={0} max={100} value={this.state.domain_knowledge} onInput={this.handleDomainSliderChange}/>
                                            <Form.Label>{this.state.domain_knowledge}</Form.Label>
                                            </Form.Group>
                                        </div>
                                    </Form>
                                    <hr/>
                                </div>
                            }
                            {
                                this.state.show_demographics === true && this.state.domain_knowledge_changed && this.state.programming_knowledge_changed &&
                                <Link to={'/knowledge_evaluation'}>
                                    <Button variant="primary" onSubmit={this.syncLocalStorageState}>Starte Studie</Button>
                                </Link>
                            }
                        </Col>
                    </Row>
                    }
                </Container>
                <p align={"right"}>
                    {localStorage.getItem("group")}
                </p>
            </>
        )
    }
}

export default Introduction;