import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import json5 from "json5";
import { HashLink as Link } from 'react-router-hash-link';

class Global_Explanation extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            pos_nr_risk_patients:0,
            pos_nr_no_risk_patients:0,
            neg_nr_risk_patients:0,
            neg_nr_no_risk_patients:0,
            pos_proportion:0,
            loading: false,
            aggregated_explanation: "",
            aggregated_explanation_neg_risk:"",
            aggregated_explanation_neg_no_risk:"",
            show_aggregated_explanation: false,
            progress:0,
            translation: {
                "0":"Heart scan result",
                "1":"Chest pain (yes/no)",
                "2":"Maximum heart rate at Exercise Test sufficient (yes/no)",
                "3":"Sex",
                "4":"Blood pressure in rest state above average (yes/no)",
                "5":"Age",
                "6":"Smoking history (in years)",
                "7":"Blood sugar level above average (yes/no)",
                "8":"Cholesterol level above average (yes/no)",
                "9":"Family history of heart disease (yes/no)",
                "neg":"negative impact on the assessed risk",
                "pos":"positive impact on the assessed risk"
            },
            values : {
                "Feeling chest pain at exercise test": "Yes, No",
                "Maximum heart rate at Exercise Test sufficient": "Yes, No",
                "Chest pain": "Yes, No",
                "Number of vessels clogged": "0-4",
                "Heart scan result": "No defect, Fixed defect, Unfixed defect",
                "Blood pressure in rest state": "Above average, Below average",
                "Cholesterol level": "Above average, Below average",
                "Smoking history": "Number of years",
                "Sex": "Male, Female"
            }
        }
    }

    componentDidMount() {

            this.setState({loading:true});

            // for setting of loading bar
            this.intervalId = setInterval(() => {
                  this.setState(prevState => ({
                    progress: prevState.progress + 5
                  }));
                }, 60);

            axios.get('/api/globalExplanation', {
                params: {
                    'feature':localStorage.getItem("feature"),
                    'trend':localStorage.getItem('expectation_'+localStorage.getItem("round"))
                }
            })
                .then((response) => {
                    localStorage.setItem("importancy_proportion", response.data["importancy_proportion"])
                    localStorage.setItem("importancy_proportion_"+localStorage.getItem("round"), response.data["importancy_proportion"])
                    localStorage.setItem("pos_proportion", response.data["pos_proportion"])
                    localStorage.setItem("neg_proportion", response.data["neg_proportion"])
                    localStorage.setItem("pos_proportion_"+localStorage.getItem("round"), response.data["pos_proportion"])
                    localStorage.setItem("neg_proportion_"+localStorage.getItem("round"), response.data["neg_proportion"])
                    localStorage.setItem("neg_index_"+localStorage.getItem("round"), response.data["neg_index"])
                    this.setState({pos_nr_no_risk_patients:response.data["pos_nr_no_risk_patients"]})
                    this.setState({pos_nr_risk_patients:response.data["pos_nr_risk_patients"]})
                    this.setState({neg_nr_no_risk_patients:response.data["neg_nr_no_risk_patients"]})
                    this.setState({neg_nr_risk_patients:response.data["neg_nr_risk_patients"]})
                    this.setState({aggregated_explanation:this.dictToList(response.data["sorted_features"])})
                    this.setState({aggregated_explanation_neg:this.dictToList(response.data["sorted_features_neg"])})
                    this.setState({aggregated_explanation_neg_risk:this.dictToList(response.data["sorted_features_neg_risk"])})
                    this.setState({aggregated_explanation_neg_no_risk:this.dictToList(response.data["sorted_features_neg_no_risk"])})
                    this.setState({aggregated_explanation_pos_risk:this.dictToList(response.data["sorted_features_pos_risk"])})
                    this.setState({aggregated_explanation_pos_no_risk:this.dictToList(response.data["sorted_features_pos_no_risk"])})
                }).catch((err) => {
                    console.log('Error occurred' + err)}).finally(() => {
                        this.setState({loading:false})}
            )
    }

    dictToList(dict) {
      const keys = Object.keys(json5.parse(dict));
      const aggregated_explanations = json5.parse(dict);
      const result = [];
      for (let i = 0; i < keys.length; i++) {
        const entry = new Map();
        entry.set(keys[i],aggregated_explanations[keys[i]])
        result.push(entry);
      }
      return result;
    }

    linkToNextRound = () => {
        localStorage.setItem("round",String(parseInt(localStorage.getItem("round"))+1))
    }

    linkToNextPhase = () => {
        localStorage.setItem("round","1")
    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                        <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                {
                                    this.state.loading === false &&
                                    <div style={{'maxWidth':"500px"}}>
                                        <p style={{'paddingBottom': "1%", 'paddingTop': '2%'}}>
                                            <b>1)</b> In
                                            <b>
                                            {
                                                " "+localStorage.getItem("importancy_proportion")+"% "
                                            }
                                            </b>
                                            of the cases, the algorithm considered
                                            {
                                                localStorage.getItem("feature") === "Maximum heart rate at Exercise Test sufficient" &&
                                                <div>
                                                    <p>the fact that a patient has reached a sufficient heart rate at an exercise test as being important for predicting the patients heart disease risk. </p>
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Number of vessels clogged" &&
                                                <div>
                                                    <p>the fact that a patient has a higher number of clogged vessels than another as being important for predicting the patients heart disease risk. </p>
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Heart scan result" &&
                                                <div>
                                                    <p>the fact that a patient had a prior heart defect has been fixed,
                                                        as opposed to another examined patient, which did not have any prior heart defect, as being important for predicting the patients heart disease risk. </p>
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Feeling chest pain at exercise test" &&
                                                <div>
                                                    <p>the fact that a patient experienced chest pain at an exercise test, where an exhausting activity had to be performed, as being important for predicting the patients heart disease risk. </p>
                                                </div>
                                            }
                                            <br/>
                                            <b>2)</b> In
                                            <b>
                                            {
                                                " "+localStorage.getItem("pos_proportion")+" "
                                            }
                                            </b>
                                            of these cases, the algorithm also (just like you've selected before) sees the fact
                                            {
                                                localStorage.getItem("feature") === "Maximum heart rate at Exercise Test sufficient" &&
                                                <div>
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "-1" &&
                                                        <div>
                                                            <p>that a patient has reached a sufficient heart rate at an exercise test
                                                            as an indicator for this patient <b>not being at risk</b>  for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "1" &&
                                                        <div>
                                                            <p>that a patient has reached a sufficient heart rate at an exercise test
                                                            as an indicator for this patient <b>being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Number of vessels clogged" &&
                                                <div>
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "-1" &&
                                                        <div>
                                                            <p>that a patient has a higher number of clogged vessels than another
                                                            as an indicator for this patient <b>not being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "1" &&
                                                        <div>
                                                            <p>that a patient has a higher number of clogged vessels than another
                                                            as an indicator for this patient <b>being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Heart scan result" &&
                                                <div>
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "-1" &&
                                                        <div>
                                                            <p>that a patient had a prior heart defect has been fixed,
                                                            as opposed to another examined patient, which did not have any prior heart defect,
                                                            as an indicator for this patient <b>not being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "1" &&
                                                        <div>
                                                            <p>that a patient had a prior heart defect has been fixed,
                                                            as opposed to another examined patient, which did not have any prior heart defect,
                                                            as an indicator for this patient <b>being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Feeling chest pain at exercise test" &&
                                                <div>
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "-1" &&
                                                        <div>
                                                            <p>that a patient experienced chest pain at an exercise test,
                                                                where an exhausting activity had to be performed
                                                            as an indicator for this patient <b>not being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                    {
                                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "1" &&
                                                        <div>
                                                            <p>that a patient experienced chest pain at an exercise test,
                                                                where an exhausting activity had to be performed,
                                                            as an indicator for this patient <b>being at risk</b> for incurring a heart disease.</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                            localStorage.getItem("neg_proportion") !== "0.0%" &&
                                            <div>
                                                <p>Want to see an example where the algorithm decided differently than you?</p>
                                                <Link to={'/counterfactual'}>
                                                    <Button variant="outline-primary">Continue to example</Button>
                                                </Link>
                                            </div>
                                            }
                                        </p>
                                        <hr/>
                                        <div>
                                            {
                                                localStorage.getItem("neg_proportion") !== "0.0%" &&
                                                <p>Or:</p>
                                            }
                                            {
                                                localStorage.getItem("feature") !== "Feeling chest pain at exercise test" &&
                                                <Link to={'/global_expectation'}>
                                                    <Button variant="primary" onClick={() => this.linkToNextRound()}>Continue to next round</Button>
                                                </Link>
                                            }
                                            {
                                                localStorage.getItem("feature") === "Feeling chest pain at exercise test" &&
                                                <Link to={'/introduction_local_learning'}>
                                                    <Button variant="primary" onClick={() => this.linkToNextPhase()}>Continue with study</Button>
                                                </Link>
                                            }
                                        </div>

                                    </div>
                                }
                            </Col>
                        </Row>
                            {
                                    this.state.loading === true &&
                                    <div style={{"paddingTop":"1%"}}>
                                            <p>Please wait shortly while further details are loaded.</p>
                                            <div style={{ width: '400px', height: '20px', border: '1px solid #ccc' }}>
                                                <ProgressBar animated now={this.state.progress}/>
                                            </div>
                                    </div>
                                }
                </Container>
            </>
        )
    }
}

export default Global_Explanation;