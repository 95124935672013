import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {Tooltip} from 'react-tooltip'
import DOMPurify from "dompurify";
import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

class Aggregated_Explanation extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            progress: 0,
            loading: false,
            explanations: "",
            patient: {},
            groundtruth: {},
            prediction: {},
            importance: {
                'Heart scan result': 'success',
                'Chest pain': 'error',
                'Number of vessels clogged': 'warning',
                 'Maximum heart rate at Exercise Test sufficient (flag)': 'info',
                 'Feeling chest pain at exercise test': 'secondary'
            },
            translation: {
                "Sex":{
                    0:"female",
                    1:"male"
                },
                "thalach_flg": {
                    0:"no",
                    1:"yes"
                },
                "famhist":{
                    0:"no",
                    1:"yes"
                },
                "exang":{
                    0:"no",
                    1:"yes"
                },
                "thal":{
                    0:"no defect",
                    1:"fixed defect detected",
                    2:"unfixed defect detected"
                },
                "Chest pain":{
                    0:"no",
                    1:"yes"
                },
                "Blood sugar level":{
                    0:"no",
                    1:"yes"
                },
                "Blood pressure in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Heart rate in rest state":{
                    0:"no",
                    1:"yes"
                },
                "Cholesterol level":{
                    0:"no",
                    1:"yes"
                }
            }
        }
    }

    componentDidMount() {

        this.refreshData()

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    linkToNextRound = (proceed) => {
        if(proceed === true){
            localStorage.setItem("round","0")
        } else {
            localStorage.setItem("round",String(parseInt(localStorage.getItem("round"))+1))
        }
    }

    refreshData(){

        this.setState({progress:0})

        let index = localStorage.getItem("neg_index_"+localStorage.getItem("round"))

            this.setState({"loading":true});
            axios.get('/api/counterfactualIndex', {
                params: {
                    'session_id':localStorage.getItem('session_id'),
                    'index': index,
                    }}
            )
                .then((response) => {
                    localStorage.setItem("counterfactual", String(response.data["counterfactual"]))
                }).catch((err) => {
                    console.log('Error occurred' + err)}).finally(() => {
                        this.setState({loading:false})
                    })

        axios.all([
            axios.get('/api/patient', {params: {'index': index}}),
            axios.get('/api/prediction', {params: {'index': index, 'session_id':localStorage.getItem('session_id')}})]).
        then(axios.spread((patient_response, prediction_response, groundtruth_response ) => {
              localStorage.setItem("prediction",prediction_response.data["Prediction"]);
              localStorage.setItem("groundtruth",patient_response.data["num"]);
              this.setState({"patient":patient_response.data})
        }));

    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                        <Container>
                        <Row style={{'paddingBottom': "1%"}}>
                            <Col>
                                <h4 style={{'paddingBottom': "1%", 'paddingTop': '2%'}}></h4>
                                {
                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "-1" &&
                                    <p>
                                        Below, you find an example for a patient where the algorithm sees
                                        the {localStorage.getItem("feature")} as an indicator for this patient being
                                        at risk for a heart disease. Remember that you have selected that the {localStorage.getItem("feature")}
                                        should have the exact opposite impact, namely as an indicator for a patient not being
                                        at risk for a heart disease. Also, an explanation on the necessary changes is supplied at
                                        the bottom of the page.
                                    </p>
                                }
                                {
                                    localStorage.getItem("expectation_"+localStorage.getItem("round")) === "1" &&
                                    <p>
                                        Below, you find an example for a patient where the algorithm sees
                                        the {localStorage.getItem("feature")} as an indicator for this patient not being
                                        at risk for a heart disease. Remember that you have selected that the {localStorage.getItem("feature")}
                                        should have the exact opposite impact, namely as an indicator for a patient being
                                        at risk for a heart disease. Also, an explanation on the necessary changes is supplied at
                                        the bottom of the page.
                                    </p>
                                }
                                <hr/>
                                <p>
                                    <ul>
                                        {
                                            window.screen.width >= 1280 &&
                                            <li>For further information, hover over the characteristic</li>
                                        }
                                        {
                                            window.screen.width < 1280 &&
                                            <li>For further information, click on the characteristic</li>
                                        }
                                        <li>The 5 most important characteristics are colored from yellow (important) to
                                            red (very important)
                                        </li>
                                    </ul>
                                </p>
                                <hr/>
                                <h6>
                                    Demographics
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <Tooltip anchorId="sex" content="Rather descriptive characteristic"
                                                 place="top"/>
                                        <Tooltip anchorId="age"
                                                 content="Older patients tend to have a higher heart disease risk" place="top"/>
                                        <Tooltip anchorId="famHist"
                                                 content="A possible genetic disposition for a heart disease leads to a higher heart disease risk"
                                                 place="top"/>
                                        <Tooltip anchorId="smokingHist"
                                                 content="Smoking is a commonly known heart disease risk factor"
                                                 place="top"/>
                                        <tr>
                                            <th>Sex</th>
                                            <td><p id="sex">{this.state.translation["Sex"][this.state.patient["Sex"]]}</p></td>
                                        </tr>
                                        <tr>
                                            <th>Age</th>
                                            <td><p id="age">{this.state.patient.Age}</p></td>
                                        </tr>
                                        <tr>
                                            <th>Family history of heart disease</th>
                                            <td><p id="famHist">{this.state.translation["famhist"][this.state.patient["Family history of heart disease"]]}</p></td>
                                        </tr>
                                        <tr>
                                            <th>Number of years smoking</th>
                                            <td><p id="smokingHist">{this.state.patient["Smoking history"]}</p></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Exercise test results
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <Tooltip anchorId="maxHeartRate" content="A common indicator for a healthy heart"
                                                 place="top"/>
                                        <Tooltip anchorId="chestPainExercise"
                                                 content="A common indicator for a healthy heart" place="top"/>
                                        <Tooltip anchorId="cloggedVessels"
                                                 content="Are associated with a higher risk of a myocardic infarct"
                                                 place="top"/>
                                        <tr>
                                            <th><p id="maxHeartRate" style={{color: "#ffb700"}}>Maximum heart rate reached
                                                sufficient (for age group and gender)</p></th>
                                            <td>{this.state.translation["thalach_flg"][this.state.patient["Maximum heart rate at Exercise Test sufficient"]]}</td>
                                        </tr>
                                        <tr>
                                            <th><p id="chestPainExercise" style={{color: "#ffc700"}}>Feeling chest pain at
                                                exercise test</p></th>
                                            <td>{this.state.translation["exang"][this.state.patient["Feeling chest pain at exercise test"]]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Examination results
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                        <Tooltip anchorId="heartScanResult"
                                                 content="Prior fixed defects or unfixed defects lead to a higher heart disease risk than no defects"
                                                 place="top"/>
                                        <tr>
                                            <th><p id="cloggedVessels" style={{color: "#ff8600"}}>Number of vessels
                                                clogged</p></th>
                                            <td>{this.state.patient["Number of vessels clogged"]}</td>
                                        </tr>
                                        <tr>
                                            <th><p style={{color: "#FF0000"}}>Heart scan result</p></th>
                                            <td><p id="heartScanResult">{this.state.translation["thal"][this.state.patient["Heart scan result"]]}</p></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <h6>
                                    Measurements
                                </h6>
                                <Table striped bordered hover style={{maxWidth: "350px"}}>
                                    <tbody>
                                    <Tooltip anchorId="bloodPressure" content="Puts additional strain on the heart and arteries, is associated with a higher risk of a myocardic infarct"
                                                 place="top"/>
                                        <Tooltip anchorId="heartRateRest"
                                                 content="Higher levels are associated with a higher risk of a myocardic infarct" place="top"/>
                                        <Tooltip anchorId="bloodSugar"
                                                 content="Higher levels can contribute to a hardening and narrowing of arteries, are associated with a higher risk of a myocardic infarct"
                                                 place="top"/>
                                        <Tooltip anchorId="cholLevel"
                                                 content="Higher levels can lead to a narrowing of arteries, are associated with a higher risk of a myocardic infarct"
                                                 place="top"/>
                                        <tr>
                                            <th>Blood pressure in rest state above average?</th>
                                            <td><p id="bloodPressure">{this.state.translation["Blood pressure in rest state"][this.state.patient["Blood pressure in rest state"]]}</p></td>
                                        </tr>
                                        <tr>
                                            <th>Heart rate in rest state above average?</th>
                                            <td><p id="heartRateRest">{this.state.translation["Heart rate in rest state"][this.state.patient["Heart rate in rest state"]]}</p></td>
                                        </tr>
                                        <tr>
                                            <th>Blood sugar level above normal level?</th>
                                            <td><p id="bloodSugar">{this.state.translation["Blood sugar level"][this.state.patient["Blood sugar level"]]}</p></td>
                                        </tr>
                                        <tr>
                                            <th>Cholesterol level above average?</th>
                                            <td><p id="cholLevel">{this.state.translation["Cholesterol level"][this.state.patient["Cholesterol level"]]}</p></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr/>
                                    <h6 style={{paddingBottom: "1%"}}>
                                         <b>
                                            Algorithm decision:
                                         </b>
                                    </h6>
                                    {
                                        localStorage.getItem("prediction") === "0" &&
                                        <b>
                                            <p>The algorithm has predicted that this patient would not have a heart disease.</p>
                                        </b>
                                    }
                                    {
                                        localStorage.getItem("prediction") === "1" &&
                                        <b>
                                            <p>The algorithm has predicted that this patient would have a heart disease.</p>
                                        </b>
                                    }
                                    {
                                        this.state.loading === true &&
                                        <div style={{"paddingTop":"1%"}}>
                                            <p>Please wait shortly while further details are loaded.</p>
                                            <div style={{ width: '400px', height: '20px', border: '1px solid #ccc' }}>
                                                <ProgressBar animated now={this.state.progress}/>
                                            </div>
                                        </div>
                                    }
                                        {
                                            this.state.loading === false  &&
                                            <div>
                                                    <br/>
                                                    <p><b>The algorithm would have decided differently</b> if <b>ALL</b> of the
                                                    following changes would have been made:</p>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(localStorage.getItem("counterfactual"))}}>
                                                    </div>
                                            </div>
                                        }
                                <hr/>
                                <h6>
                                    <b>
                                      Real outcome:
                                    </b>
                                </h6>
                                {
                                    localStorage.getItem("groundtruth" + "_" + localStorage.getItem("round")) === "0" &&
                                    "In the end, this patient did not have a heart disease. "
                                }
                                {
                                    localStorage.getItem("groundtruth" + "_" + localStorage.getItem("round")) === "1" &&
                                    "In the end, this patient did have a heart disease. "
                                }
                                {
                                    localStorage.getItem("groundtruth" + "_" + localStorage.getItem("round")) === localStorage.getItem("prediction" + "_" + localStorage.getItem("round")) &&
                                    "So, the algorithm did a good assessment."
                                }
                                {
                                    localStorage.getItem("groundtruth" + "_" + localStorage.getItem("round")) !== localStorage.getItem("prediction" + "_" + localStorage.getItem("round")) &&
                                    "So, the algorithm did not do a good assessment."
                                }
                            </Col>
                        </Row>
                        <hr/>
                        <Row style={{'paddingBottom': "1%"}}>
                            {
                                (localStorage.getItem("round") === "5") &&
                                <Link to={'/introduction_familiarization'}>
                                    <Button variant="primary" onClick={() => this.linkToNextRound(true)}>Continue Study</Button>
                                </Link>
                            }
                            {
                                (localStorage.getItem("round") !== "5") &&
                                <Link to={'/questions'}>
                                <Button variant="primary" onClick={() => this.linkToNextRound(false)}>Continue Study</Button>
                                </Link>
                            }
                        </Row>
                </Container>
            </>
        )
    }
}

export default Aggregated_Explanation;