import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Introduction_Test extends React.Component {

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            {
                                (localStorage.getItem("explanations") !== "yes") &&
                                <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>Now, you'll enter the next phase.</h4>
                            }
                            {
                                (localStorage.getItem("explanations") === "yes") &&
                                <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>In the next phase, you will again be asked to make predictions, on the same patients as in the first prediction phase.</h4>
                            }
                            <h5 style={{"maxWidth":"550px"}}>
                                Please note: Now, you have to make predictions on <i>what the algorithm will give as an advice</i>, not on the <i>real outcome</i>! <br/><br/>
                            </h5>
                            <h6 style={{"maxWidth":"550px"}}>
                                So, please try to leave out your intuition and try to remember the information on how the
                                algorithm behaved before. <br/><br/>
                            </h6>
                            <p>
                                Each of the five rounds will take place as follows:
                            </p>
                            <ol>
                                <li>Presentation of the patient to be decided on.</li>
                                <li>You will be asked to give a prediction on <i>what the algorithm</i> will give as an advice.</li>
                            </ol>
                        </Col>
                    </Row>
                    <Row style={{'paddingTop': "1%"}}>
                        <Link to={'/patient_presentation?round=6'}>
                            <Button variant="primary">Go to first task</Button>
                        </Link>
                    </Row>
                </Container>
            </>
        )
    }

}

export default Introduction_Test;