import axios from 'axios';
import React from 'react';
import {Container, Row, Col, Navbar, Button, Table} from 'react-bootstrap'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

class Prediction_Results extends React.Component {

    state = {
        decision: "",
        groundtruth: "",
        round: ""
    };

    componentDidMount() {
        let decision = (new URLSearchParams(window.location.search)).get("decision")
        this.setState({"decision":decision})
        let round = ""
        if (localStorage.getItem("round") === null) {
            localStorage.setItem("round", 1)
            this.setState({"round": 1})
            localStorage.setItem("own_decision_1",decision)
        } else {
            round = localStorage.getItem("round")
            this.setState({"round": parseInt(round) + 1})
            localStorage.setItem("own_decision_"+String(parseInt(round)+1),decision)
        }
        axios.get('/api/patient',
            {
                params: {'round': round}
            }
        ).then
        (
            response => {
                this.setState({"groundtruth": response.data["G3"]})
            }
        )

    }

    render() {
        return (
            <>
                <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand>
                    </Navbar.Brand>
                </Container>
                </Navbar>
                <Container>
                    <Row>
                        <Col>
                            <h4 style={{'paddingBottom': "1%",'paddingTop':'2%'}}>Real Outcome: {
                                    (this.state.groundtruth === 0) &&
                                <p>
                                    The student was not successful.
                                </p>
                            } {
                                    (this.state.groundtruth === 1) &&
                                <p>
                                    The student was successful.
                                </p>
                            }</h4>
                            {
                                (this.state.groundtruth === 0 && this.state.decision === "no") &&
                                    <p>
                                        You were right.
                                    </p>
                            }
                            {
                                (this.state.groundtruth === 1 && this.state.decision === "yes") &&
                                    <p>
                                        You were right.
                                    </p>
                            }
                            {
                                (this.state.groundtruth === 0 && this.state.decision === "yes") &&
                                    <p>
                                        You were wrong, sadly.
                                    </p>
                            }
                            {
                                (this.state.groundtruth === 1 && this.state.decision === "no") &&
                                    <p>
                                        You were wrong, sadly.
                                    </p>
                            }
                        </Col>
                    </Row>
                    <Row style={{'paddingTop': "1%"}}>
                        {
                            localStorage.getItem("round") === "4" &&
                            <Link to={'/Conclude_Study'}>
                               <Button variant="primary">Conclude study</Button>
                           </Link>
                        }
                        {
                            localStorage.getItem("round") < "4" &&
                            <Link to={'/Patient_Presentation?round='+(parseInt(localStorage.getItem("round"))+1)}>
                               <Button variant="primary">Go to next round</Button>
                           </Link>
                        }
                    </Row>
                </Container>
            </>
        )
    }

}

export default Prediction_Results;